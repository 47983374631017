import React, {useEffect,useState} from 'react';
import * as ReactDOM from 'react-dom';
import { useStateContext } from '../contexts/ContextProvider';

import { Banner2} from '../components';
import { Header2} from '../components';

import {Aws_controller} from '../components';

import { LineChartGrease} from '../components/index';
import { GaugeCluster} from '../components/index';
import { ChartCluster} from '../components/index';
import { MeterBarCluster} from '../components/index';
import { LineChartGreH2O,LineChartGrease_PQ, LineChartGrease_Iron, LineChartGrease_Copper} from '../components/index';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

// AWS BANNER PULL //
const sqlquery = "SELECT TO_CHAR(MAX(sample_date),'YYYY-MM-DD') as sample_date1, MAX(sample_date) as sample_date2  from  dev.public.grease;";
const thedata = await Aws_controller(sqlquery)
const Positions = ['AFT','FWD','STBD','Port','Drum'];

const lastfulldate = thedata[0][0].stringValue
const lastfulldate2 = thedata[0][1].stringValue
//console.log('Thing:',lastfulldate2)
// ################ //
const Grease = () => {
    const { currentColor } = useStateContext();
    const [pos, setPos] = useState('AFT');
    const styles = {
      container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        
      },
      chartWrapper: {
        flex: '1 1 21%',  // Ensure 4 charts in a row with some spacing
        paddingBottom: '56.25%', // 16:9 aspect ratio
        position: 'relative',
        margin: '10px',
        minWidth: '200px', // Minimum width to maintain readability
      },
      chartItem: {
        flex: '1 1 30%',  // Ensure 4 charts in a row with some spacing
       // paddingBottom: '56.25%', // 16:9 aspect ratio
        //position: 'relative',
        margin: '2px',
        //minWidth: '200px', // Minimum width to maintain readability
      },
    };

  return (
    <div className='mt-12'>

        <Banner2 page="Grease Sampling" statement={'Lastest Data Set Received:'} lastdate={lastfulldate}/>
       
        <div className='flex flex-wrap w-full pt-0 pl-10 pr-10 justify-center align-top rounded-2xl'>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
              <div className='w-full justify-left'>
                <Header2  title="Water Content [ppm]" thacolor={currentColor} />
              </div>
              <div className="w-full ">
                <LineChartGreH2O />
              </div>
            </div>
          </div>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-white dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 ml-4 p-5'>
              <div className='w-full justify-left'>
                <Header2 title="PQ Index" thacolor={currentColor}/>
              </div>
              <div className='w-full items-center align-center rounded-2xl 
                            bg-white dark:bg-black dark:text-gray-100 
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
                <div className="w-full align-center justify-center ">
                  <LineChartGrease_PQ  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='flex flex-wrap w-full pt-0 pl-10 pr-10 justify-center align-top rounded-2xl'>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
              <div className='w-full justify-left'>
                <Header2  title="Iron (Fe) [ppm]" thacolor={currentColor} />
              </div>
              <div className="w-full ">
                <LineChartGrease_Iron />
              </div>
            </div>
          </div>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-white dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 ml-4 p-5'>
              <div className='w-full justify-left'>
                <Header2 title="Copper (Cu) [ppm]" thacolor={currentColor}/>
              </div>
              <div className='w-full items-center align-center rounded-2xl 
                            bg-white dark:bg-black dark:text-gray-100 
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
                <div className="w-full align-center justify-center ">
                  <LineChartGrease_Copper  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="m-0 md:m-10  p-2 rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
            <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
                <div className='flex flex-wrap w-3/12 justify-left items-center align-middle rounded-2xl pl-0'>
                  <Header2 title="Specific Position Glance:" thacolor={currentColor}  />
                </div>
                <div className='flex flex-wrap w-9/12  align-middle rounded-2xl pr-0'>
                  <div className="w-full flex items-center ">
                    <div className='flex flex-wrap w-11/12  align-middle justify-end rounded-2xl pr-0'>
                      Select Position:
                    </div>
                    <div className="flex flex-wrap w-1/12 m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                      <DropDownListComponent id="ddlelement" 
                      dataSource={Positions} 
                      placeholder={'AFT'}
                      onChange={e => setPos(e.target.value)}
                      />
                    </div>
                  </div>
                  
                </div>
            </div>
            <div className="m-2 p-2 rounded-xl
                        bg-white dark:text-gray-200 dark:bg-black
                        border-slate-600 dark:border-slate-400 border-4">

              <div className="w-full">
                <ChartCluster id="H2O" loc={pos} />
              </div>
              <div className="w-full">
                <GaugeCluster loc={pos} />
              </div>
              
              <div className="flex flex-wrap w-full">
                <div className="pr-2  text-black dark:text-gray-200
                        bg-white dark:bg-black
                       border-slate-700 dark:border-slate-400 border-4 rounded-lg pb-2"
                  style={styles.chartItem}>
                  <div className='flex flex-wrap dark:text-gray-200
                  justify-center pb-4'>
                    Wear Metals
                  </div>
                  <MeterBarCluster id="wear" loc={pos} dadate={lastfulldate2}/>
                </div>
                
                <div className="pl-2 pr-2  text-black dark:text-gray-200
                        bg-white dark:bg-black
                       border-slate-700 dark:border-slate-400 border-4 rounded-lg pb-2"
                   style={styles.chartItem}>

                  
                  <div className='flex flex-wrap dark:text-gray-200
                    justify-center pb-4'>
                    Additive Particles
                  </div>
                  <MeterBarCluster id="additive" loc={pos} dadate={lastfulldate2}/>
                </div>

                <div className=" pl-2 pr-2  text-black dark:text-gray-200
                        bg-white dark:bg-black
                       border-slate-700 dark:border-slate-400 border-4 rounded-lg pb-2"
                       style={styles.chartItem}>
                  
                  <div className='flex flex-wrap dark:text-gray-200
                    justify-center pb-4'>
                    Contaminate Particles
                  </div>
                  <MeterBarCluster id="contaminate" loc={pos} dadate={lastfulldate2}/>
                </div>
              
              </div>
            </div>


        </div>


        {/* <div className="w-full">
            <MeterBarCluster />
          </div> */}

        <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                     bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                     border-slate-600 dark:border-white border-4">
        <div className="w-full">
          <LineChartGrease />
        </div>
      </div>
      



    </div>
  )
}

export default Grease