import React, {useEffect,useState} from 'react'
import * as ReactDOM from "react-dom";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, HistogramSeries } from '@syncfusion/ej2-react-charts';
import { GiHistogram } from 'react-icons/gi';
import { chartLoad } from "@syncfusion/ej2/pivotview";


const HistogramRotation = (dacolor) => {
    const [dadata, setDadata] = useState([])
    // const fetchData = () => {
    // fetch(`http://localhost:59476/api/RotationBreakOut`)
    //     .then(response => {
    //     return response.json()
    //     })
    //     .then(data => {
    //     setDadata(data)
    //     })
    // }
    // useEffect(() => {
    //     fetchData()
    //     }, [])
    // ###################### //

    
    const thecolor= dacolor
    const primaryxAxis = { majorGridLines: { width: 0 }, minimum: -3, maximum: 3 };
    const primaryyAxis = {  minimum: 0 , interval: 100, majorTickLines: { width: 0 } ,lineStyle: { width: 0 } };
    const legendSettings = { visible: false };
    const tooltipsettings = { enable: true };
    const marker = { dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#7f7f7f' } } };
    return (
        <div className='w-full'>
            <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis}
                             tooltip={tooltipsettings} legendSettings={legendSettings} title = '90-day Distribution' width='100%'>
                <Inject services={[HistogramSeries, Legend, Tooltip, Category, DataLabel]}/>
                <SeriesCollectionDirective>
                <SeriesDirective dataSource={dadata} yName='BreakOut' name='Score' type='Histogram' marker={marker}
                                 fill= {'#ffc81f'} showNormalDistribution={false} columnWidth={0.9} binInterval={.05}>
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    );
};
export default HistogramRotation