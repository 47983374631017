import React from 'react';
import "../styles.css";
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, employeesGrid , HomeSSGrid, HomeSSData} from '../data/dummy';
import { Bulbb} from '../components';
const HomeSwivelTable = ({datadata}) => {
    const backgroundlook1 = { 
        backgroundColor: '#2c3331'}
  return (
    <div>
        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 dark:border-white border-4 rounded-3xl" style={backgroundlook1}>
            <GridComponent
                id="gridcomp"
                dataSource={HomeSSData}
                width="auto"
                gridLines='Both'
                >
                <ColumnsDirective>
                    {HomeSSGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
                ))}
                </ColumnsDirective>
                <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
            </GridComponent>
        </div>
    </div>
  );
};

export default HomeSwivelTable