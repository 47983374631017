import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, employeesGrid , SwivelAlertGrid, SwivelFBTestData} from '../data/dummy';
import { Bulbb} from '../components';
import '../Grids.css'


const SwivelFaultBoard = () => {
  return (
    <div>
        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-white border-4 rounded-3xl">
            <GridComponent
                className='Grids'
                id="gridcomp"
                dataSource={SwivelFBTestData}
                width="auto"
                gridLines='Both'
                theme= 'bootstrap5-dark'
                style={{backgroundColor: 'darksalmon',
                        colorScheme: 'dark'}}
                >
                <ColumnsDirective>
                    {SwivelAlertGrid.map((item, index) =>(
                <ColumnDirective customAttributes={{ class: 'Grids' ,
                                                    backgroundColor: 'darksalmon'}} key={index} {...item} />
                ))}
                </ColumnsDirective>
                <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
            </GridComponent>
        </div>
    </div>
  )
}

export default SwivelFaultBoard