import React ,{useRef} from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { Banner2} from '../../components';
import { Header2 }  from '../../components';
import { S3imagesModule,S3CSVModule} from '../../components/index'
import ReactiveButton from 'reactive-button';


const oneone = await S3imagesModule('FIG_1-1')
const onetwo = await S3imagesModule('FIG_1-2')
const onethr33 = await S3imagesModule('FIG_1-3')
const onefour = await S3imagesModule('FIG_1-4')
const onefive = await S3imagesModule('FIG_1-5')

const twoone = await S3imagesModule('FIG_2-1')
const twotwo = await S3imagesModule('FIG_2-2')
const twothr33 = await S3imagesModule('FIG_2-3')
const twofour = await S3imagesModule('FIG_2-4')
const twofive = await S3imagesModule('FIG_2-5')
const twosix = await S3imagesModule('FIG_2-6')
const twosev = await S3imagesModule('FIG_2-7')
const two8 = await S3imagesModule('FIG_2-8')
const twonine = await S3imagesModule('FIG_2-9')

const threeone = await S3imagesModule('FIG_3-1')
const threetwo = await S3imagesModule('FIG_3-2')
const three3 = await S3imagesModule('FIG_3-3')
const threefour = await S3imagesModule('FIG_3-4')
const threefive = await S3imagesModule('FIG_3-5')

const fourone = await S3imagesModule('FIG_4-1')
const fourtwo = await S3imagesModule('FIG_4-2')
const fourthr33 = await S3imagesModule('FIG_4-3')

const fiveone = await S3imagesModule('FIG_5-1')
const fivetwo = await S3imagesModule('FIG_5-2')
const five3 = await S3imagesModule('FIG_5-3')
const fivefou = await S3imagesModule('FIG_5-4')

const alims = await S3CSVModule('ALIMS_DS')
const hvesr = await S3CSVModule('HVESR_DS')
const icss = await S3CSVModule('ICSS_DS')
const lube = await S3CSVModule('LUBE_DS')
const tpms = await S3CSVModule('TPMS_DS')

const CurrentReport = () => {
  const { currentColor } = useStateContext();
  // Using refs to refer to the sections
  const firstSectionRef = useRef(null);
  //const headingRef = useRef(null);
  const secondSectionRef = useRef(null);
  const thirdSectionRef = useRef(null);
  const fourthSectionRef = useRef(null);
  console.log('alimslook',alims)

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const buttonstyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };


    //const fileUrl = ''; // Replace with your file's URL
    //const fileName = 'file.pdf'; // Name you want for the downloaded file

    const handleDownloadClick = ({fileUrl, fileName}) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    //console.log('a',a)
    a.click();
    document.body.removeChild(a);
    //console.log('ref',a.href)
    };





  return (
    
    <div className='mt-12'>
      
    <Banner2 page="Current Report" statement={'Last Report:'}/>


    <div className='m-2 md:m-10 mt-2 p-2 md-10  rounded-3xl
                  bg-white dark:text-gray-200 dark:bg-slate-200
                  border-slate-600 dark:border-white border-4'>
        <div className= 'mt-1 flex flex-wrap justify-center' >
            <p className="font-semibold text-3xl" style={{color:currentColor}}>Current Data Sets</p>                     
        </div>
        <div style={buttonstyle}>

            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'ALIMS'}
                onClick={() => handleDownloadClick({fileUrl:alims,fileName:'ALIMS_DS.csv'})}
            />
             <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'HVESR'}
                onClick={() => handleDownloadClick({fileUrl:hvesr,fileName:'HVESR_DS.csv'})}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'ICSS'}
                onClick={() => handleDownloadClick({fileUrl:icss,fileName:'ICSS_DS.csv'})}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'LUBE'}
                onClick={() => handleDownloadClick({fileUrl:lube,fileName:'LUBE_DS.csv'})}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'TPMS'}
                onClick={() => handleDownloadClick({fileUrl:tpms,fileName:'TPMS_DS.csv'})}
            />
        </div>
    </div>

    <div className='m-2 md:m-10 mt-2 p-2 md-10  rounded-3xl
                  bg-white dark:text-gray-200 dark:bg-slate-200
                  border-slate-600 dark:border-white border-4'>

        <div style={buttonstyle}>
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'Scroll to Section 2'}
                onClick={() => scrollToSection(firstSectionRef)}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'Scroll to Section 3'}
                onClick={() => scrollToSection(secondSectionRef)}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'Scroll to Section 4'}
                onClick={() => scrollToSection(thirdSectionRef)}
            />
            <ReactiveButton 
                shadow
                rounded
                color='secondary'
                buttonState={'idle'}
                idleText={'Scroll to Section 5'}
                onClick={() => scrollToSection(fourthSectionRef)}
            />
        </div>

        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
             bg-white dark:bg-white dark:border-slate-600">
            <div>
                <Header2  title= "Section 1: StationKeeping Performance" thacolor= {currentColor}/>
            </div>
            <div className="flex m-2 flex-wrap gap-1 items-center">



                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={oneone}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={onetwo}  />
                    </div>
                </div>
            
                
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={onethr33}   />
                    </div>
                </div>
                
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={onefour}  />
                    </div>
                </div>
                
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={onefive}  />
                    </div>
                </div>
            </div>
        </div>

        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
             bg-white dark:bg-white dark:border-slate-600"> 
        <div ref={firstSectionRef} className="section">
          <Header2  title= "Section 2: Heading & Rotation Performance" thacolor= {currentColor}/>
        </div>
            <div className="flex m-2 flex-wrap gap-1 items-center">
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twoone}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twotwo}  alt="tpms_sq" />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twothr33}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twofour}  />
                    </div>
                </div>

                
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twofive}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twosix}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twosev}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={two8}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={twonine}  />
                    </div>
                </div>
            </div>
        </div>

        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
             bg-white dark:bg-white dark:border-slate-600"> 
            <div ref={secondSectionRef} className="section">
            <Header2  title= "Section 3: Anchor Leg Performance" thacolor= {currentColor}/>
            </div>
            <div className="flex m-2 flex-wrap gap-1 items-center">
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={threeone}  />
                    </div>
                </div>
        
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={threetwo}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={three3}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={threefour}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={threefive}  />
                    </div>
                </div>
            </div>
        </div>

        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
             bg-white dark:bg-white dark:border-slate-600"> 
            <div ref={thirdSectionRef} className="section">
            <Header2  title= "Section 4: Slip Ring Condition Monitoring" thacolor= {currentColor}/>
            </div>
            <div className="flex m-2 flex-wrap gap-1 items-center">
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fourone}  />
                    </div>
                </div>
        
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fourtwo}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fourthr33}  />
                    </div>
                </div>
            </div>
        </div>

        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
             bg-white dark:bg-white dark:border-slate-600"> 
            <div ref={fourthSectionRef} className="section">
            <Header2  title= "Section 5: Lubrication System Monitoring" thacolor= {currentColor}/>
            </div>
            <div className="flex m-2 flex-wrap gap-1 items-center">
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fiveone}  />
                    </div>
                </div>
        
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fivetwo}  />
                    </div>
                </div>

                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={five3}  />
                    </div>
                </div>
                <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
                    <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
                    bg-white dark:bg-white dark:border-slate-600">
                        <img  src={fivefou}  />
                    </div>
                </div>
            </div>
        </div>

      
    </div>
    </div>
  )
}

export default CurrentReport