import React , {useEffect,useState} from 'react'
import { Header} from '../components';
import { useStateContext } from '../contexts/ContextProvider';


const names = ['James', 'John', 'Paul', 'Ringo', 'George'];






const ALIMS_TS = () => {


  const [users, setUsers] = useState([])
const encodedValue = encodeURIComponent("Relative Turret Position Easting")
const fetchData = () => {
  fetch(`http://localhost:59476/api/Tpms`)
    .then(response => {
      return response.json()
    })
    .then(data => {
      setUsers(data)
    })
}

useEffect(() => {
  fetchData()
}, [])
const { currentColor } = useStateContext();



  return (
    <div>
            {users.filter(para => para.PARAMETER.includes('Heave')).map(filteredName => (
        <li>
          Mean : {filteredName.MEAN}
        </li>
      ))}
    </div>
  )
}

export default ALIMS_TS