import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi'

import moment from "moment";
import {
  ComposedChart,
  ResponsiveContainer,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area
} from "recharts";

import { useStateContext } from '../contexts/ContextProvider';

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection,Search,
  Inject, Edit, Toolbar, Sort, Filter,  FilterSettingsModel,
  ExcelExport, ExcelExportProperties } from '@syncfusion/ej2-react-grids';


import { SwivelInfluxGrid, InfluxRangeSetting} from '../data/dummy';

//Visualize Data
//<div>
//{data.map(filteredName => (
//  <li>
//    Time : {filteredName.datime}
//     Value : {filteredName.davalue}
//  </li>
//))}
//</div>


const token = 'FauvX9fS2hFA7XcAljs1oPRt29aqO8iLviS299a_HpxSaVrO-lQY_nlJas-SngZ7SZm9zY_dCmpGJSZcYy7uSg=='
const org = 'Asset Integrity'
const bucket = 'SWIVEL'
const url = 'http://localhost:8086'


let sharp = "1m"





let gridquery = `from(bucket: "SWIVEL")
|> range(start: 2022-02-08T20:28:03.000Z, stop: 2022-02-09T20:28:03.000Z)
|> filter(fn: (r) =>
  r._measurement == "SWIVEL"
)
|> drop(columns: ["EpochTime","System"])
|> aggregateWindow(every: 1h, fn: mean, createEmpty: true)
`;
let gridComp = GridComponent | null;

export const InfuxDB_Graph = () => {
  const [sharp, setSharp] = useState("1m");
  const [data, setData] = useState([]);


  const influxQuery = async () => {
    let res = [];
    const query =  `from(bucket: "SWIVEL")
    |> range(start: 2022-02-08T20:28:03.000Z, stop: 2022-02-09T20:28:03.000Z)
    |> filter(fn: (r) =>
      r._measurement == "SWIVEL" and
      r._field == "0001Production1SwivelTorque"
    )
    |> drop(columns: ["EpochTime","System"])
    |> aggregateWindow(every: `+ sharp +`, fn: mean, createEmpty: true)
    `;
    //create InfluxDB client
    const queryApi = new InfluxDB({ url, token }).getQueryApi(org);
    //make query
    queryApi.queryRows(query, {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row);
        //push rows from query into an array object
        res.push(o);
      },
      complete() {
        let finalData = []

        for (let i = 0; i < res.length; i++) {
          let point = {};
          
          point["dakey"] = res[i]["_field"];
          point["davalue"] = res[i]["_value"];
          point["datime"] = res[i]["_time"];
          //point["datime"] = moment(res[i]["_time"]).format('MMM-Do-YY,hA')

          finalData.push(point);
        }
        setData(finalData);
      },
      error(error) {
        console.log("query failed-sda ", error);
      }
    });
  };

  const pulling = useEffect(() => {
    let res = [];
    influxQuery();
  }, [sharp]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TooltipComponent content="1-Day" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("1m"); pulling.influxQuery()}}
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            1D
          </button>
        </TooltipComponent>
        <TooltipComponent content="1-Week" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("5m"); pulling.influxQuery()}}
          
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            1W
          </button>
        </TooltipComponent>
        <TooltipComponent content="1-Month" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("15m"); pulling.influxQuery()}}
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            1M
          </button>
        </TooltipComponent>
        <TooltipComponent content="3-Month" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("30m"); pulling.influxQuery()}}
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            3M
          </button>
        </TooltipComponent>
        <TooltipComponent content="1-Year" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("4h"); pulling.influxQuery()}}
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            1Y
          </button>
        </TooltipComponent>
        <TooltipComponent content="All Time" position='top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => {setSharp("6h"); pulling.influxQuery()}}
          style ={{ background: "#0ff770",
          borderRadius: '50%'}}>
            All
          </button>
        </TooltipComponent>
      </div>
      <div>
      <ResponsiveContainer width="95%" height={400}>
        <ComposedChart data={data}>
          <CartesianGrid />
          <Tooltip     viewBox = {
            {x: "datime",
             y: "davalue"}}/>
          <Line
            stroke="#0ff770"
            strokeWidth={2}
            dataKey="davalue"
            dot={true}
          />
          <Area stroke="#bf04b3" fill="#0ff770" dataKey="davalue" />
          <XAxis dataKey="datime" axisLine={false} tickLine={false} tickFormatter={date => moment(date,"YYYY-MM-DDThh:mm:ssZ").format("MM/DD/YYYY hh:mm")}/>
          <YAxis />
        </ComposedChart>
      </ResponsiveContainer>
      </div>
    </div>
  );
};

export const InfuxDB_Grid = () => {
  const [data, setData] = useState([]);

  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings = ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "Swiveldata.xlsx"}
        );
      }
    }
  }


  const pulling = useEffect(() => {
    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
      //make query
      await queryApi.queryRows(gridquery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row);
          //push rows from query into an array object
          res.push(o);
        },
        complete() {
          let finalData = []

          for (let i = 0; i < res.length; i++) {
            let point = {};
            
            point["dakey"] = res[i]["_field"];
            point["davalue"] = res[i]["_value"];
            point["datime"] = res[i]["_time"];

            finalData.push(point);
          }
          setData(finalData);
        },
        error(error) {
          console.log("query failed-sda ", error);
        }
      });
    };

    influxQuery();
  }, []);
  return (
    <div>
      <div>
          <GridComponent
            ref={gc => gridComp = gc}
            id="gridcomp"
            dataSource={data}
            allowPaging ={true}
            allowSorting
            allowExcelExport = {true}
            allowPdfExport
            toolbar={['Delete','Search','ExcelExport']}
            toolbarClick={toolbarBtnClick}
            allowFiltering={true}
            enableFilter={true}
            filterSettings={filterOption}
            editSettings={{ allowDeleting: true,
            allowEditing: true, allowFiltering: true}}
            width="auto">
            <ColumnsDirective>
              {SwivelInfluxGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
          </GridComponent>
      </div>
    </div>
  );
};
