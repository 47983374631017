import React, {useEffect,useState} from 'react'
import { useStateContext } from '../contexts/ContextProvider';

import { Banner2, Header2,SlipRingLogTable} from '../components';

import { LineChartSlipRings } from '../components/index'
import {Aws_controller} from '../components';


// Grid-Table Stuff //
import { GridComponent } from '@syncfusion/ej2-react-grids';

// const SERVICE_URI_L =  'http://localhost:59476/api/LVEOSR';
// const SERVICE_URI_H =  'http://localhost:59476/api/HVESR';
// new DataManager({
//   url: SERVICE_URI_L,
//   adaptor: new WebApiAdaptor
// }).executeQuery(new Query().take(8)).then((e) => {
//   //e.result will contain the records
// });

let gridComp = GridComponent | null; 
let gridComp2 = GridComponent | null; 

const sqlquery = "SELECT DATE(DATE_ADD('s',AVG(EXTRACT(EPOCH FROM extracted_timestamps.timestamp)),'1970-01-01')) AS average_date FROM (SELECT timestamp FROM dev.public.ds_hvesr ORDER BY timestamp DESC LIMIT 130) AS extracted_timestamps;";
const thedata = await Aws_controller(sqlquery)
const lastfulldate = thedata[0][0].stringValue

// ################ //
const SlipRings = () => {
  const { currentColor } = useStateContext();
  // SQL GRID STUFF //
  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings = ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "LVEOSRdata.xlsx"}
        );
      };
    };
  };
  const toolbarBtnClick2 = (args) => {
    if(gridComp2){
      if(args.item.id.includes("excelexport")){
        gridComp2.excelExport(
          {fileName : "HVESRdata.xlsx"}
        );
      };
    };
  };

  // ###################### //
  return (
    <div className='mt-12'>
      <Banner2 page="Slip Rings Dashboard" statement={'Lastest Data Set Received:'} lastdate={lastfulldate}/>

      <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
        <Header2 category="30-day Trending" title="Hourly Resolution" thacolor={currentColor} />
        <div className="w-full">
          <LineChartSlipRings />
        </div>
        <br></br>
        <Header2 category="" title="High-Resolution"  thacolor={currentColor}/>
        <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
        </div>
      </div>

      <div className="m-4 md:m-10 mt-24 p-10  rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
        <div className="w-full">
          <div className='mt-12'>
            <Header2 title="Recent Logs (30-days)" thacolor={currentColor} />
            <SlipRingLogTable />
          </div>
        </div>
      </div>

    </div>
  );
};

export default SlipRings
/*


  const [ldata, setLdata] = useState([])
  const fetchData = () => {
    fetch(`http://localhost:59476/api/LVEOSR`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setLdata(data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [hdata, setHdata] = useState([])
  const fetchData2 = () => {
    fetch(`http://localhost:59476/api/HVESR`)
      .then(response2 => {
        return response2.json()
      })
      .then(data2 => {
        setHdata(data2)
      })
  }

  useEffect(() => {
    fetchData2()
  }, [])



Grid Stuff for LVEOSR/HVESR
         <GridComponent
            ref={gc => gridComp = gc}
            id="gridcomp"
            dataSource={ldata}
            allowPaging ={true}
            allowSorting
            allowExcelExport = {true}
            allowPdfExport
            toolbar={['Delete','Search','ExcelExport']}
            toolbarClick={toolbarBtnClick}
            allowFiltering={true}
            enableFilter={true}
            filterSettings={filterOption}
            editSettings={{ allowDeleting: true,
            allowEditing: true, allowFiltering: true}}
            width="auto">
            <ColumnsDirective>
              {slipringGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
          </GridComponent>
        </div>
        <br></br>
        <Header category="" title="HVSER Hourly Resolution" />
        <div className="w-full">
        <GridComponent
            ref={gc => gridComp2 = gc}
            id="gridcomp2"
            dataSource={hdata}
            allowPaging ={true}
            allowSorting
            allowExcelExport = {true}
            allowPdfExport
            toolbar={['Delete','Search','ExcelExport']}
            toolbarClick={toolbarBtnClick2}
            allowFiltering={true}
            enableFilter={true}
            filterSettings={filterOption}
            editSettings={{ allowDeleting: true,
            allowEditing: true, allowFiltering: true}}
            width="auto">
            <ColumnsDirective>
              {slipringGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
          </GridComponent>
        </div>
        */