import React from 'react'
import * as ReactDOM from 'react-dom';

import { Banner2, SwivelAlertBoard, SwivelFaultBoard, HomeSwivelTable} from '../components';
import { Header, Header2} from '../components';

import {SwivelHomeData } from '../data/dummy';

import { useStateContext } from '../contexts/ContextProvider';
import { LineChartSwivel } from '../components/index'
import { InfuxDB_Graph } from '../components/Influx_DB_SWIVEL';

import { InfluxDB} from '@influxdata/influxdb-client';
//import { url, token, org } from './env'

import {Aws_controller} from '../components';
//Swivel Board Stuff
import BannerSwivelList from '../components/BannerSwivelList';

// Grid-Table Stuff
import { swivelGrid} from '../data/dummy';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection,Search,
  Inject, Edit, Toolbar, Sort, Filter,  FilterSettingsModel,
  ExcelExport, ExcelExportProperties } from '@syncfusion/ej2-react-grids';
  import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';

// const SERVICE_URI =  'http://localhost:59476/api/Swivel';
// new DataManager({
//   url: SERVICE_URI,
//   adaptor: new WebApiAdaptor
// }).executeQuery(new Query().take(8)).then((e) => {
//   //e.result will contain the records
// });

let gridComp = GridComponent | null;  

// AWS BANNER PULL //
const sqlquery = "SELECT DATE(DATE_ADD('s',AVG(EXTRACT(EPOCH FROM extracted_timestamps.timestamp)),'1970-01-01')) AS average_date FROM (SELECT timestamp FROM dev.public.ds_swivel ORDER BY timestamp DESC LIMIT 900) AS extracted_timestamps;";
const thedata = await Aws_controller(sqlquery)
const lastfulldate = thedata[0][0].stringValue
// ################ //
const SwivelSystem = () => {

  const { currentColor } = useStateContext();

  
  // InfluxDB Data Stuff //
  const token = 'FauvX9fS2hFA7XcAljs1oPRt29aqO8iLviS299a_HpxSaVrO-lQY_nlJas-SngZ7SZm9zY_dCmpGJSZcYy7uSg=='
  const org = 'Asset Integrity'
  const bucket = 'SWIVEL'

  const client = new InfluxDB({url: 'http://localhost:8086', token: token})

  const queryApi = client.getQueryApi(org)

  const query = `from(bucket: "SWIVEL") |> range(start: -1h)`
  queryApi.queryRows(query, {
    next(row, tableMeta) {
      const o = tableMeta.toObject(row)
      console.log(`${o._time} ${o._measurement}: ${o._field}=${o._value}`)
    },
    error(error) {
      console.error(error)
      const statuss = 'Finished ERROR'
    },
    complete() {
      const statuss = 'Finished SUCCESS'
    },
    })
  // ###################### //

  // SQL Data Stuff //
  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings = ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "SWIVELdata.xlsx"}
        );
      };
    };
  };

  // ###################### //

  return (
    <div className='mt-12'>

      <Banner2 page="Swivel System Dashboard" statement={'Lastest Data Set Received:'} lastdate={lastfulldate}/>
      <div>
        <BannerSwivelList />
      </div>

      <div className="m-2 md:m-10 mt-24 p-2 md-10 md:p-10  rounded-3xl
                     bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                     border-slate-600 dark:border-white border-4">
        <div className="flex justify-between">
            <Header2 title="SWIVEL STACK BOARD"  thacolor= {currentColor} />
        </div>
        <div>
          <HomeSwivelTable datadata={SwivelHomeData}/>
        </div>
      </div>


      <div className="m-2 md:m-10 mt-24 p-2 md-10 md:p-10 rounded-3xl
                     bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                     border-slate-600 dark:border-white border-4">
        <div className="items-center">
          <div className="w-full">
            <LineChartSwivel />
          </div>
        </div>
        <br></br>
        <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
        </div>
      </div>
        {/* <Header2 category="" title="High-Resolution" thacolor= {currentColor} />
        <div className="items-center">
          <div className="w-full">
            <InfuxDB_Graph />
          </div>
        </div>
      </div>

      <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                     bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                     border-slate-600 dark:border-white border-4">
        <Header2 category="Logs Entries" title="Historic Log Table" thacolor= {currentColor} />
        <div className="w-full">
          LOG TABLE GOES HERE
        </div>
      </div>


      <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <Header2 title="Historic Tables" thacolor={currentColor} />
          <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
          </div>
        </div> */}



    </div>
  );
};

export default SwivelSystem

/* 
Grid Stuff for Swivel
    <div className="w-full">
      <GridComponent
        ref={gc => gridComp = gc}
        id="gridcomp"
        dataSource={users}
        allowPaging ={true}
        allowSorting
        allowExcelExport = {true}
        allowPdfExport
        toolbar={['Delete','Search','ExcelExport']}
        toolbarClick={toolbarBtnClick}
        allowFiltering={true}
        enableFilter={true}
        filterSettings={filterOption}
        editSettings={{ allowDeleting: true,
        allowEditing: true, allowFiltering: true}}
        width="auto">
        <ColumnsDirective>
          {swivelGrid.map((item, index) =>(
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
      </GridComponent>
    </div>
    <br></br>
    <Header category="" title="High-Resolution" />
    <div className="w-full">
      <InfuxDB_Grid />
    </div>
    */