import React, {useEffect,useState} from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, employeesGrid , RotationTAvgGrid, RotationTCumGrid} from '../../data/dummy';





export default function RotationTableAVG() {
    const [dadata, setDadata] = useState([])
    const backgroundlook1 = { 
        backgroundColor: '#343434'}

    // const fetchData = () => {
    // fetch(`http://localhost:59476/api/RotationAVG`)
    //     .then(response => {
    //     return response.json()
    //     })
    //     .then(data => {
    //     setDadata(data)
    //     })
    // }

    // useEffect(() => {
    // fetchData()
    // }, [])
    // ###################### //
    return (
      <div>
          <div className="m-2 md:m-1 mt-4 p-2 md:p-3 rounded-3xl border-white border-4" style={backgroundlook1}>
              <GridComponent
                  id="gridcomp"
                  dataSource={dadata}
                  width="auto"
                  gridLines='Both'
                  >
                  <ColumnsDirective>
                      {RotationTAvgGrid.map((item, index) =>(
                  <ColumnDirective key={index} {...item} />
                  ))}
                  </ColumnsDirective>
                  <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
              </GridComponent>
          </div>
      </div>
    );
  };

  export const RotationTableCUM = ({}) => {
    const [dadata, setDadata] = useState([])
    const backgroundlook1 = { 
        backgroundColor: '#343434'}


    // const fetchData = () => {
    // fetch(`http://localhost:59476/api/RotationCUM`)
    //     .then(response => {
    //     return response.json()
    //     })
    //     .then(data => {
    //     setDadata(data)
    //     })
    // }

    // useEffect(() => {
    // fetchData()
    // }, [])
    // ###################### //

    
    return (
      <div>
          <div className="m-2 md:m-1 mt-4 p-2 md:p-3 rounded-3xl border-white border-4" style={backgroundlook1}>
              <GridComponent
                  id="gridcomp"
                  dataSource={dadata}
                  width="auto"
                  gridLines='Both'
                  >
                  <ColumnsDirective>
                      {RotationTCumGrid.map((item, index) =>(
                  <ColumnDirective key={index} {...item} />
                  ))}
                  </ColumnsDirective>
                  <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
              </GridComponent>
          </div>
      </div>
    );
  };
