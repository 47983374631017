import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

import sofeclogo from "../data/imgs/AIG_logo_SOFEC_white.png";
import ucimg from "../data/imgs/Sangomar_Digital_V1.png";
import { HeaderBanner }  from '../components';

//            <img className="sofec_logo" src={sofeclogo}   alt="AIG_logo_SOFEC_white"/>
//<br></br>


const Banner = ({page}) => {
    const { currentColor } = useStateContext();
    const backgroundlook = { 
        width: "98%",
        height: "92px",
        backgroundImage: "url(" + ucimg + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'}
    const backgroundlook1 = { 
            width: "25%",
            height: "92px",
            backgroundColor: currentColor,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'}
    const backgroundlook2 = { 
            width: "96%",
            height: "100px",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }
    const backgroundlook3 = { 
                width: "50%",
                height: "100px",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }
        

    return (
        <div>
            <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  mt-0 md:m-10  md-10  rounded-3xl border-slate-600 dark:border-white border-4' style={backgroundlook2}>
                <div className=" flex  flex-wrap justify-between">
                    <div className='flex ml-4 mt-0 rounded-3xl justify-start items-center' style={backgroundlook3}>
                        <div>
                            <HeaderBanner title= {page} thacolor= {currentColor} />
                        </div>
                    </div>
                    <div className='flex flex-wrap mt-0 rounded-3xl justify-end align-top' style={backgroundlook1}>
                        <div className='flex flex-wrap mt-0 rounded-3xl justify-start items-center' style={backgroundlook}>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default Banner