import React, { createContext, useContext, useState} from "react";

import avatar from '../data/avatar.jpg';

const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false
  }
  
  export const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState (initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#1A97F5');
    const [currentMode, setCurrentMode] = useState('Dark');
    const [themeSettings, setThemeSettings] = useState(false);//
    const [param, setParam] = useState('Relative Turret Position Easting');
    const [profileName, setProfileName] = useState('Guest');
    const [profilePic, setProfilePic] = useState(avatar);
    const [data, setData] = useState(true);
    const [info, setInfo] = useState(true);
    const [blockedMenu, setBlockedMenu] = useState(false)
  
  
  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });
  
  
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
    //setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
    //setThemeSettings(false);
  };
  return (
    <StateContext.Provider 
        value={{
             activeMenu,
             setActiveMenu,
             isClicked,
             setIsClicked,
             handleClick,
             screenSize, setScreenSize,
             currentColor, setColor,
             setCurrentColor, setCurrentMode,
             currentMode,setMode,
             themeSettings, setThemeSettings,
             param, setParam,
             profileName, setProfileName,
             profilePic, setProfilePic,
             data, setData,
             info, setInfo,
             blockedMenu,setBlockedMenu
        }}
        >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
export const UseStateContext = () => useContext(StateContext);
//asdasd