import React , {useEffect,useState} from 'react';



import { lineCustomSeries, LinePrimaryXAxisAll, LinePrimaryYAxisAll, FakeBreakData } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import moment from "moment";
import {
    ComposedChart,
    ResponsiveContainer,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
  } from "recharts";

const LineChartBreakOut = ({daRange}) => {
  const [dadata, setDadata] = useState([])
  // const fetchData = () => {
  // fetch(`http://localhost:59476/api/RotationBreakOutLine`)
  //     .then(response => {
  //     return response.json()
  //     })
  //     .then(data => {
  //     setDadata(data)
  //     })
  // }
  // useEffect(() => {
  //     fetchData()
  //     }, [])

    
//const dadate = moment().format("YYYY-MM-DD")
const dadate = "01/07/2023"
  // ###################### //
dadata.forEach(d => {
    d.Timestamp = moment(d.Timestamp).valueOf(); // date -> epoch
  });
const dateFormatter = date => {
    // return moment(date).unix();
    return moment(date).format('DD/MM/YY HH:mm');
  };
   
  return (
    <div>
      
      <div>
      <ResponsiveContainer width="95%" height={400}> 
        <ComposedChart data={daRange == '1day'? dadata.filter(para => para.Datee.includes(dadate)) :  dadata }>
          <CartesianGrid />
          <Tooltip     viewBox = {
            {x: "Datee",
             y: "CCW",
            }}/>
          <Line stroke="#0ff770" strokeWidth={0} color= "#0ff770"
            dataKey="CW" dot={{ stroke: "#0ff770", strokeWidth: 3}} />
          <Line stroke="#0ff770" strokeWidth={0}
            dataKey="CCW" dot={{ stroke: "#0ff770", strokeWidth: 3}}/>
          <XAxis dataKey="Timestamp" axisLine={true} tickLine={true}   type={"number"} scale={"time"}
                  domain={["dataMin","dataMax"]} tickFormatter={dateFormatter} />
          <YAxis domain={[-3,3]} />
        </ComposedChart>
      </ResponsiveContainer>
      </div>
    </div>
    
  )
}

export default LineChartBreakOut


/*dateFormatter = date => {
  // return moment(date).unix();
  return moment(date).format('DD/MM/YY HH:mm');
};
dadata.forEach(d => {
  d.Timestamp = moment(d.Timestamp).valueOf(); // date -> epoch
  {dadate}
});*/