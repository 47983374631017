import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';

import { lineCustomSeries, LinePrimaryXAxisTpmsDS, LinePrimaryYAxisAll } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { Header, Header2} from '../../components';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import {Aws_controller} from '../../components';;

// AWS PULL //
const statement = "SELECT timestamp as ts, parameter as par,mean as bar ,min as low, max as high FROM dev.public.ds_tpms WHERE timestamp >= DATEADD(MONTH, -1, GETDATE()) ORDER BY timestamp ASC";//SELECT _epochtime as ts, _channel as par,_value as val FROM dev.public.ts_tpms WHERE _epochtime <= DATEADD(WEEK, -1, '2022-05-31') ORDER BY _epochtime ASC;";
const thedata = await Aws_controller(statement)
//////////////

const tpmsParms = ['FPSO Heading','Heave','Pitch Angle','Relative Turret Position Easting','Relative Turret Position Northing','Roll Angle',
'Turret Absolute Offset','Turret Offset Bearing','Wind direction (from) 1-minute rolling average',  'Wind speed 1-minute rolling average'];
const LineChartTPMS_TS = () => {
  
    const [param, setParam] = useState('FPSO Heading');
    const { currentColor } = useStateContext();
    const { currentMode } = useStateContext();
    const [users, setUsers] = useState([])
    const encodedValue = encodeURIComponent('FPSO Heading')
    const [info, setInfo] = useState([])
    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, []);
    //const result = info.length
    console.log('The Output:', info)
    let points=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["TIMESTAMP"] = info[i][0].stringValue;
      point["PARAMETER"] = info[i][1].stringValue;
      point["MEAN"] = info[i][2].doubleValue;
      point["MIN"] = info[i][3].doubleValue;
      point["MAX"] = info[i][4].doubleValue;
      points.push(point);}
    console.log('The Output7:', points)
    //////////////////
  
  
    const lineCustomSeries2 = [
      { dataSource: points.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'Mean',
        width: '2',
        fill: 'grey',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'grey' }},
        type: 'Line' },
        { dataSource: points.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MIN',
        name: 'Min',
        width: '3',
        fill: 'bg-white',
        marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
                border: { width: 2, color: 'Red' }},
        type: 'Line' },
        { dataSource: points.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MAX',
        name: 'Max',
        width: '0',
        color: 'green',
        marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
                border: { width: 2, color: 'green' }},
        type: 'Line' },
    ];
  
    return (
      <div>
        <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
            <div className='flex flex-wrap w-9/12  align-middle rounded-2xl pr-0'>
              <div className="w-full flex items-center ">
                
                <div className="w-full m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                  <DropDownListComponent id="ddlelement" 
                  dataSource={tpmsParms} 
                  placeholder='FPSO Heading'
                  onChange={e => setParam(e.target.value)}
                  />
                </div>
              </div>
              
            </div>
        </div>
        
        <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                      bg-white dark:bg-black
                    border-slate-600 dark:border-white border-4 ">
          <div className='w-full'>
            <ChartComponent
              id="line-chart"
              height="420px"
              primaryXAxis={LinePrimaryXAxisTpmsDS}
              primaryYAxis={LinePrimaryYAxisAll}
              chartArea={{ border: { width: 0 } }}
              tooltip={{ enable: true }}
              enableMouseWheelZooming
              zoomSettings={
                {enableMouseWheelZooming: true,
                enablePan: true,
                enableScrollbar: true}
            }
              background={currentMode === 'Dark' ? 'bg-secondary-dark-bg ' : '#fff'}
              legendSettings={{ background: 'white' }}
            >
              <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
              <SeriesCollectionDirective>
                {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>
        </div>
    </div>
    );
  };
  
  export default LineChartTPMS_TS;