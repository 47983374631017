import React, {useEffect,useState} from 'react'
import { UseStateContext } from '../contexts/ContextProvider';
import AWS, { Config } from 'aws-sdk';
import { RedshiftDataClient, ExecuteStatementCommand, GetStatementResultCommand,DescribeStatementCommand } from "@aws-sdk/client-redshift-data";
import { DescribeClustersCommand }  from "@aws-sdk/client-redshift";
import { fromEnv } from "@aws-sdk/credential-provider-env";
//import {AWS} from 'aws-sdk'
import  { RedshiftClient }  from  "@aws-sdk/client-redshift";
import { consoleLogger } from '@influxdata/influxdb-client';
import { Result } from 'postcss';
function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}
function wait4client(dacaller) {
  return new Promise.resolve(dacaller).then(result=>console.log(result.$metadata.Id));
}


//   function FetchData1(cmd1) {
  
const fetchData = async (cmd1) => {
  try{
    const execution = await client.send(cmd1)
    //await timeout(1000)
    const idstrang = execution.$metadata.requestId
    const input2 = { // GetStatementResultRequest
      Id: idstrang,}
    console.log("idstrangg:",input2)
    try{
      const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
      const value2 = await client.send(command2);
      //console.log('connect2:',value2)
      const resp2Recs = value2.Records.map((m)=>m)
      // console.log('Records',resp2Recs)
      return resp2Recs
       } catch (error){
        await timeout(1000)
        const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
        const value2 = await client.send(command2);
        //console.log('connect3:',value2)
        const resp2Recs = value2.Records.map((m)=>m)
        //console.log('Records:',resp2Recs)
        return resp2Recs
       }
    //await useEffect(() =>{setInfo(input2)}, []);

  } catch (error) {
    try{
      const execution = await client.send(cmd1)
      await timeout(1000)
      const idstrang = execution.$metadata.requestId
      const input2 = { // GetStatementResultRequest
        Id: idstrang,}
      console.log("idstrangg:",input2)
      try{
        const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
        const value2 = await client.send(command2);
        //console.log('connect2:',value2)
        const resp2Recs = value2.Records.map((m)=>m)
        // console.log('Records',resp2Recs)
        return resp2Recs
         } catch (error){
          await timeout(1000)
          const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
          const value2 = await client.send(command2);
          //console.log('connect3:',value2)
          const resp2Recs = value2.Records.map((m)=>m)
          //console.log('Records:',resp2Recs)
          return resp2Recs
         }
      //await useEffect(() =>{setInfo(input2)}, []);
  
    } catch (error) {
      // error handling.
      console.log('error hit')
      console.log(error)
      return null;
    }
  }
};





//console.log('1aa')
const creds = new AWS.Credentials('AKIA4JFHL3P3XXCYUQOQ', 'M/JIpq5YhZcqSQyt/Gq7l+HnsG7HSONM2j0keohm',null)
const client= new RedshiftDataClient({credentials: creds,
region:"us-east-2"});
//console.log('2aa')

const Aws_controller = async (SQLstatment) => {
    
    //console.log('1a')
    //console.log('2a')
    const input1 = { // ExecuteStatementInput
        Sql: SQLstatment,  //"SELECT * FROM dev.public.ds_alims WHERE mean is not null limit 10;",
        ClusterIdentifier: "sofec-datawarehouse",
        //DbUser: "sangomar-dev-svc-account",
        Database: "dev", // required
        //StatementName: "STRING_VALUE",
        WithEvent: true,
        //WorkgroupName: "STRING_VALUE",
        //ClientToken: "STRING_VALUE",
        }; 

    //Execute Statement
    const command1 = new ExecuteStatementCommand(input1);
    //console.log('3a')
    //console.log(command1)
    //Retreave Data
    const finaldata = fetchData(command1)
    //console.log('final data:',finaldata)

    //Set AWS data
    

 

    //const dadataa = FetchData1(command1)
    // .log('asdasd12132123',dadataa);
    // //const r93 = new Promise(await client.send(command1)).then((val) => console.log('Trying this:',val.$metadata.Id)) //.catch(e => { console.log('Problem:' , DescribeStatementCommand({Id: e.$metadata.requestId})) });
    // const r9 = new Promise((resolve, reject) => {
    //   // do something
    //   const value = client.send(command1)
    //   resolve(value);
    // });
    //  //Makle r10 a promise too
    // const r10 = r9.then((value) => {
    //   //const the_call2 = value
    //   const strang = value.$metadata.requestId
    //   console.log('boom',strang);
    //   return strang // logs 'The value of the Promise'
    // }).then((value) =>{
    //   console.log('TheId23:',value)
    //   timeout(1000)
    //   const input2 = { // GetStatementResultRequest
    //     Id: value,
    //   }
    //   return input2
    // }).then((value) => {
    //   console.log('TheId234:',value)
    //   const command2 = new GetStatementResultCommand(value)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
    //   const value2 = client.send(command2);
    //   console.log('connect2:',value2)
    //   //const desciption2 = new DescribeStatementCommand(input2.Id);
    //   //console.log('desc23423r2:', desciption2)
    //   return value2
    // });
    // console.log('adasd')
    
    // const a = await r9;
    // const Id =  a.$metadata.requestId
    // console.log('TheId:',Id)
    // const b = await r10;
    // const answer =  b.$metadata
    // console.log('TheAnwser:',answer)


    // const input2 = { // GetStatementResultRequest
    //   Id: Id ,// required
    //   //NextToken: "STRING_VALUE",
    // };
   


    //Describe Statement
    // const desciption = new DescribeStatementCommand(input2);
    // console.log('descr:', desciption.Id)
    // //Get Statement
    // const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
    // console.log('The Command2:',command2)
    // const desciption2 = new DescribeStatementCommand(input2);
    // console.log('descr2:', desciption2)


    //Get Execute Statement Result
    // const response22 = new Promise((resolve, reject) => {
    //   // do something
    //   const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
    //   const value2 = client.send(command2);
    //   resolve(value2);
    // });
    // await timeout(1000)
    // response22.then((value2) => {
    //   //const the_call2 = value
    //   const resp2Recs = value2.Records.map((m)=>m)
    //   console.log('boom2',resp2Recs);
    //   return resp2Recs // logs 'The value of the Promise'
    // });
    // console.log('adasd2')
    // //await timeout(1000)
    // const a2 = await response22;
    // //await timeout(1000)
    // console.log('TheId2:',a2)

    // const promise3 = r9.then(() => response22);

    // promise3.then(() => {
    //   // do something after both promises have resolved
    //   console.log('buggers')
    // });


    // const response2 = await client.send(command2);
    // console.log('The Response2:', response2)
    // const resp2Recs = response2.Records.map((m)=>m)
    // await timeout(1000)


    //let finalData = []

   // for (let i = 0; i < resp2Recs.length; i++) {
    //  let point = {};
      
    //  point["dakey"] = resp2Recs[i][0].stringValue;
    //  point["davalue"] = resp2Recs[i][6].doubleValue;
    //  point["datime"] = resp2Recs[i][3].stringValue;

    //  finalData.push(point);
    //}
    return (finaldata)
    
}

  export default Aws_controller
  



