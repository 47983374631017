import React, {useEffect,useState} from 'react';

import { Banner2} from '../components';
import {Header2, HomeLubeTable, LubeAlertTable, LubeLogTable} from '../components';

import { useStateContext } from '../contexts/ContextProvider';
import { LineChartLube,  } from '../components/index';
import {Aws_controller} from '../components';

// Grid-Table Stuff
import { GridComponent } from '@syncfusion/ej2-react-grids';


// const SERVICE_URI =  'http://localhost:59476/api/Lubrication';
// new DataManager({
//   url: SERVICE_URI,
//   adaptor: new WebApiAdaptor
// }).executeQuery(new Query().take(8)).then((e) => {
//   //e.result will contain the records
// });


let gridComp = GridComponent | null;  
// AWS BANNER PULL //
const sqlquery = "SELECT DATE(DATE_ADD('s',AVG(EXTRACT(EPOCH FROM extracted_timestamps.timestamp)),'1970-01-01')) AS average_date FROM (SELECT timestamp FROM dev.public.ds_lube ORDER BY timestamp DESC LIMIT 72) AS extracted_timestamps;";
const thedata = await Aws_controller(sqlquery)
const lastfulldate = thedata[0][0].stringValue
// ################ //
const LubeSystem = () => {
  const { currentColor } = useStateContext();

  // SQL Data Stuff //
  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings = ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "LubricationData.xlsx"}
        );
      };
    };
  };
  const [users, setUsers] = useState([])

  // ###################### //
  return (
    <div className='mt-12'>
      <Banner2 page="Lubrication Dashboard" statement={'Lastest Data Set Received:'} lastdate={lastfulldate}/>

      <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
        <div className="bg-slate-200 dark:text-gray-200 dark:bg-slate-600 rounded-3xl
                    border-slate-600 dark:border-white border-4">
          <HomeLubeTable />
        </div>
        <div className='mt-12'>
          <Header2 title="Alert Table" thacolor={currentColor} />
          <LubeAlertTable />
        </div>
        <div className='mt-12'>
          <Header2 title="Recent Logs (30-days)" thacolor={currentColor} />
          <LubeLogTable />
        </div>
        
      </div>



      <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
        <Header2 category="30-day Trending" title="Hourly Resolution" thacolor={currentColor} />
        
        <div className="w-full">
          <LineChartLube />
        </div>

        <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
        </div>
      </div>

      {/* <div className="m-4 md:m-10 mt-24 p-10  rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
        <Header2 category="Logs Entries" title="Historic Log Table" thacolor={currentColor} />
        <div className="w-full">
            LOG TABLE GOES HERE
        </div>
      </div>

      <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <Header2 title="Historic Tables" thacolor={currentColor} />
          <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
          </div>
        </div> */}
      
      
    </div>
  )
}

export default LubeSystem

/*
          <GridComponent
            ref={gc => gridComp = gc}
            id="gridcomp"
            dataSource={users}
            allowPaging ={true}
            allowSorting
            allowExcelExport = {true}
            allowPdfExport
            toolbar={['Delete','Search','ExcelExport']}
            toolbarClick={toolbarBtnClick}
            allowFiltering={true}
            enableFilter={true}
            filterSettings={filterOption}
            editSettings={{ allowDeleting: true,
            allowEditing: true, allowFiltering: true}}
            width="auto">
            <ColumnsDirective>
              {lubeGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
          </GridComponent>
*/

/*
  
  const fetchData = () => {
    fetch(`http://localhost:59476/api/Lubrication`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])
  */