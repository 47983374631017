import React ,{useEffect} from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore , IoIosRadioButtonOn} from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData,
         SparklineAreaData, ecomPieChartData, AlimsRadios,SwivelHomeData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { Banner2} from '../components';
import { Bulbb} from '../components';
import { Header2, AlimsRadio }  from '../components';
import { HomeSwivelTable, HomeLubeTable} from '../components';
import { TpmsWeeklyRadar } from '../components/index'


const HomeBase = () => {
    const { currentColor, blockedMenu,setBlockedMenu } = useStateContext();
    useEffect(() =>{setBlockedMenu(true)}, [true]);
  return (
    <div className='mt-12'>

      <Banner2 page="Home Station" statement={'Last 24-Hour dataset received:'}/>

      <div className='rounded-2xl'>
        <AlimsRadio />
        <div className='flex flex-wrap w-full pl-10 pr-10 mt-5 justify-center align-top rounded-2xl'>
          <div className='flex flex-wrap w-7/12'>
            <div className='justify-center align-top rounded-2xl
                            bg-white dark:bg-secondary-dark-bg  dark:text-gray-200
                            border-slate-600 dark:border-white border-4 mr-4'>
              <div className="flex flex-wrap m-0 md:m-10 mt-12 p-2 md-10 md:p-1.5 gap-10 justify-evenly text-cyan-700">
             
              <div className="flex m-0 md:m-10 mt-12 p-4 md-10 md:p-1.5  flex-wrap justify-evenly
                                bg-slate-200 dark:bg-slate-600 dark:text-gray-200 rounded-2xl 
                                border-slate-600 dark:border-white border-4">
                  <HomeLubeTable />
                </div>

                <div className="bg-slate-200 dark:bg-slate-600 dark:text-gray-200 m-3 p-4 rounded-2xl 
                                border-slate-600 dark:border-white border-4 ">
                  <div className="flex justify-between">
                    <Header2 title="SWIVEL STACK ALERT BOARD"  thacolor= {currentColor} />
                  </div>
                  <div className= 'mt-1 flex flex-wrap justify-center' >
                      <p className="font-semibold text-2xl text-yellow-400">DATA NOT YET AVAILABLE</p>
                      <br></br>
                  </div>
                  <div className=''>
                    <HomeSwivelTable datadata={SwivelHomeData}/>
                  </div>
                  <br></br>
                </div>



              </div>
            </div>
          </div>

          <div className='flex flex-wrap w-5/12 '>
            <div className='justify-center align-top rounded-2xl
                           dark:bg-secondary-dark-bg  dark:text-gray-200
                          border-slate-600 dark:border-white border-4 ml-4'>
              <div className="flex flex-wrap m-0 md:m-10 mt-12 p-2 md-10 md:p-1.5 gap-10  align-top justify-evenly text-cyan-700">
              
                <div className="bg-slate-200 dark:text-gray-200 dark:bg-slate-600 m-3 p-4 rounded-2xl md:w-780
                                border-slate-600 dark:border-white border-4 ">
                  <div className="flex justify-between">
                    <Header2 title="Turret Position History" thacolor= {currentColor} />
                  </div>
                  <div>
                    < TpmsWeeklyRadar />
                  </div>
                  <br></br>
                </div>
                
                <div className="bg-slate-200 dark:text-gray-200 dark:bg-slate-600 m-3 p-4 rounded-2xl md:w-780
                                border-slate-600 dark:border-white border-4 ">
                  <div className=' dark:text-gray-200  mt-1 justify-center '>
                    <div className= 'mt-1 flex flex-wrap justify-center' >
                      <p className="font-semibold text-3xl text-yellow-400">Significant Break-Out Angle</p>
                    </div>
                    <div className= 'mt-1 flex flex-wrap justify-center' >
                      <p className="font-semibold text-xl text-yellow-400">ROTATION DATA NOT YET AVAILABLE</p>
                    </div>
                  </div>
                  <div className='bg-secondary-dark-bg dark:text-gray-200 dark:bg-secondary-dark-bg  border-4 mt-1 p-10 flex justify-evenly rounded-3xl '>
                    
                    <div className='bg-slate-600 rounded-xl border-4  border-gray-900 flex-wrap justify-center m-4 p-10'>
                      <div className='flex flex-wrap justify-center'>
                        <p>
                          <span className='text-xl text-blue-400 font-bold'>CW</span>
                        </p>
                      </div>
                      <div className='flex flex-wrap justify-center p-2'>
                        <p>
                          <span className='text-4xl font-semibold text-green-400'>-N/A{'\u00b0'}</span>
                        </p>
                      </div>
                      <div className='flex flex-wrap justify-center'>
                        <p>
                          <span className='font-norma l'>+N/A</span>
                        </p>
                      </div>
                    </div>
                    <div className='bg-slate-600 rounded-xl border-4  border-gray-900 flex-wrap justify-center m-4 p-10'>
                      <div className='flex flex-wrap justify-center'>
                        <p>
                          <span className='text-xl text-blue-400 font-bold'>CCW</span>
                        </p>
                      </div>
                      <div className='flex flex-wrap justify-center p-2'>
                        <p>
                          <span className='text-4xl font-semibold text-red-400'>N/A{'\u00b0'}</span>
                        </p>
                      </div>
                      <div className='flex flex-wrap justify-center'>
                        <p>
                          <span className='font-normal'>N/A</span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>


              
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
  )
}

export default HomeBase

/*


THIS GOES IN SBOA WIDGET ON LINE 90 <p className="font-semibold text-xl text-yellow-400">--/--/---- (24 hr. average)</p>
*/