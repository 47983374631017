import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import loginImg from './data/imgs/AIG_logo_SOFEC_white.png'

const Home2 = () => (
  <div>
    <div className='grid grid-cols-1 sm:grid-cols-1 h-screen w-full'>

        <div className='bg-grey-600 bg-opacity-30 flex flex-col justify-center'>
        
            <form className='max-w-[400px] w-full mx-auto justify-center  bg-transparent p-8 px-8 border-4 rounded-2xl border-white'>
            <div className='justify-center'> 
                <img className='w-full justify-center ' src={loginImg} alt="" />
                <h2 className='text-xl ml-6 dark:text-white font-bold text-end gap-10'>Woodside Sangomar FPSO</h2>
            </div>
            <br></br>
            <br></br>
                <h2 className='text-4xl dark:text-white font-bold text-center gap-10'>WELCOME</h2>
                <div className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg text-center">
                    <Link to="/protected">SIGN IN with Okta</Link>
                </div>

            </form>
        </div>
    </div>
</div>

);

export default Home2;