import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Internationalization } from '@syncfusion/ej2-base';
import {  LinePrimaryYAxisAll,LinePrimaryXAxisICSS } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';

// AWS PULL //
const statement = 'SELECT "timestamp", "position", mean FROM dev.public.ds_icss WHERE timestamp >= DATEADD(MONTH, -1, (SELECT  max(date) FROM dev.public.ds_icss)) ORDER BY timestamp ASC;';
const thedata = await Aws_controller(statement)
//////////////
const icssParms = ['AFT','FWD','PORT','STBD'];

const LineChartICSS = () => {
    const [param, setParam] = useState('AFT');
    const { currentMode } = useStateContext();
    const encodedValue = encodeURIComponent('AFT')
    const [info, setInfo] = useState(thedata)

       //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.lengt asdasd
    //console.log('The OutputICss:', info)
    let datapoints = [];
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["TIMESTAMP"] = info[i][0].stringValue;
        point["POSITION"] = info[i][1].stringValue;
        point["MEAN"] = info[i][2].doubleValue;
        // push point
        datapoints.push(point);
        }
      //console.log('The OutputIcss2:',  datapoints)
  
      //////////////////

    //////////////////////
    const lineCustomSeries = [
        { dataSource: datapoints.filter(para => para.POSITION.includes("AFT")),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'AFT',
        width: '2',
        fill: 'red',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'red' }},
        type: 'Line' },
    ];
    //console.log('The OutputIcss3:',  lineCustomSeries)
    const lineCustomSeries1 = [
        { dataSource: datapoints.filter(para => para.POSITION.includes("FWD")),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'FWD',
        width: '2',
        fill: 'blue',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'blue' }},
        type: 'Line' },
    ];
    const lineCustomSeries2 = [
        { dataSource: datapoints.filter(para => para.POSITION.includes("STBD")),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'STBD',
        width: '2',
        fill: 'yellow',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'yellow' }},
        type: 'Line' },
    ];
    const lineCustomSeries3 = [
        { dataSource: datapoints.filter(para => para.POSITION.includes('PORT')),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'PORT',
        width: '2',
        fill: 'green',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'green' }},
        type: 'Line' },
    ];
    //////////////////////
    const tooltipRender = (args) => {
        let intl = new Internationalization();
        let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' });
        let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
        args.text =  `${XformattedString} : ${YformattedString}m`; 
    };
    return (
        <div>
            <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                    bg-white dark:bg-black
                  border-slate-600 dark:border-white border-4">
                <div className='w-full'>
                    <ChartComponent
                    id="line-chart"
                    height="420px"
                    primaryXAxis={LinePrimaryXAxisICSS}
                    primaryYAxis={LinePrimaryYAxisAll}
                    chartArea={{ border: { width: 0 } }}
                    tooltipRender={tooltipRender}
                    tooltip={{ enable: true }}
                    enableMouseWheelZooming
                    zoomSettings={
                        {enableMouseWheelZooming: true,
                        enablePan: true,
                        enableScrollbar: true}
                    }
                    background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
                    legendSettings={{ background: 'white' }}
                    >
                    <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
                    <SeriesCollectionDirective>
                        {lineCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries1.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries3.map((item, index) => <SeriesDirective key={index} {...item} />)}
                    </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
        </div>
    );
};

export default LineChartICSS