import React , {useEffect,useState} from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { Bulbb} from '../components';
import { Header2 }  from '../components';
import {Aws_controller} from '../components';
import moment from 'moment';

// AWS PULL //
const statement = 'SELECT DISTINCT anchor_leg,TRUNC(mean,1), timestamp FROM dev.public.ds_alims ORDER BY  timestamp DESC , anchor_leg ASC LIMIT 9;';
const thedata = await Aws_controller(statement)

const AlimsRadio = () => {
    const { currentColor } = useStateContext();
    const [info, setInfo] = useState([])

           //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, []);
    let datapoints = [];
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["AL"] = info[i][0].longValue;
        point["MEAN"] = info[i][1].doubleValue;
        point["TS"] = info[i][2].stringValue;
        if (point["MEAN"] <= 20 || point['MEAN'] >= 80  ){
            point["bulbcolor"] = 'red'
            }
        else if(point["MEAN"] <= 40 && point['MEAN'] >= 20){
            point["bulbcolor"] = 'yellow'
            }
        else if(point["MEAN"] <= 80 && point['MEAN'] >= 70){
            point["bulbcolor"] = 'yellow'
            }
        else{
            point["bulbcolor"] = 'green'
            }
        // push point
        datapoints.push(point);
        }
    //console.log('The OutputAR2:',  datapoints)
  
      //////////////////
      const findMaxNumber = (pointType) => {
        const values = datapoints.map((point) => new Date(point[pointType]));
        const maxx = moment(Math.max(...values)).format('YYYY-MM-DD hh:mm');
        
        return maxx;
      };
    //////////////////////
  return (
        <div className='m-2 p-2 pt-5 ml-10 mr-10 rounded-3xl 
                        bg-white dark:text-gray-200 dark:bg-secondary-dark-bg first-letter
                        border-slate-600 dark:border-white border-4'>
            <div className="flex justify-between">
              <Header2 title="Anchor Leg Status:"  thacolor= {currentColor} />
            </div>
            <div className='bg-slate-200 dark:bg-slate-600 flex flex-wrap
                lg:flex-nowrap items-center justify-center rounded-2xl
                border-slate-600 dark:border-white border-4'>
                  {datapoints.map((item) => (
                    
                    <div
                      className='dark:text-gray-200 rounded-2xl  md:w-56 p-4'>
                    <span className="flex text-lg font-semibold justify-center gap-3 items-center">
                      {item.AL}
                    </span>

                    <span className='flex text-lg font-semibold justify-center gap-3 items-center'>
                      <Bulbb
                        dacolor = {item.bulbcolor}
                        dasize = {20}/>
                      
                      {item.MEAN }{'\u00b0'}
                    </span>
                  </div>
                  
                  ))}
              </div>
              <br></br>
              <span className='flex text-md font-semibold  justify-end gap-3'>
                Average inclination data for :
                <span className='text-md font-semibold text-green-500 justify-end gap-3'>
                      {findMaxNumber('TS')}
                </span> 
              </span> 
        </div>
  )
}

export default AlimsRadio