import React from 'react'
import GaugeComponent from 'react-gauge-component';

const Gauge = ({theid, val, unit, gr , ye , or, rd, maxval}) => {
  return (
      
      <div>
        <GaugeComponent
            id={theid}
            arc={{
              //gradient: true,
              nbSubArcs: 75,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.15,
              padding: 0,
              subArcs: [
                {
                  limit: gr,
                  //color: '#EA4228',
                  showTick: true
                },
                {
                  limit: ye,
                  //color: '#EA4228',
                  showTick: true
                },
                {
                  limit: or,
                  //color: '#F58B19',
                  showTick: true
                },
                {
                  limit: rd,
                  //color: '#5BE12C',
                  showTick: true
                },
              ]
            }}
            value = {val}
            maxValue={maxval}
            pointer={{type: "arrow", elastic: true}}
            labels={{
              valueLabel: { formatTextValue: value => value.toString() + unit  },
              tickLabels: {
                type: 'outer',
                valueConfig: { formatTextValue: value => value , fontSize: 5 },
                ticks: [
                  { value: gr },
                  { value: ye },
                  { value: or },
                  { value: rd }
                ],
              }
            }}
        />
      </div>
    )
  }
  
  export default Gauge