import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';

import { Banner2} from '../components';
import { Header2} from '../components';

import { LineChartTPMS} from '../components/index'
import { TpmsWeeklyRadar, TpmsHistoHeading } from '../components/index'
import {Aws_controller} from '../components';


// Grid-Table Stuff
import { GridComponent } from '@syncfusion/ej2-react-grids';
//import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';

const tpmsParms = ['FPSO Heading','Heave','Pitch Angle','Relative Turret Position Easting','Relative Turret Position Northing','Roll Angle',
'Turret Absolute Offset','Turret Offset Bearing','Wind direction (from) 1-minute rolling average',  'Wind speed 1-minute rolling average'];

let gridComp = GridComponent | null;
// AWS BANNER PULL //
const sqlquery = "SELECT DATE(DATE_ADD('s',AVG(EXTRACT(EPOCH FROM extracted_timestamps.timestamp)),'1970-01-01')) AS average_date FROM (SELECT timestamp FROM dev.public.ds_tpms ORDER BY timestamp DESC LIMIT 130) AS extracted_timestamps;";
const thedata = await Aws_controller(sqlquery)
const lastfulldate = thedata[0][0].stringValue
// ################ //
const TPMS = () => {
  const { currentColor } = useStateContext();
  // SQL GRID STUFF //
  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings= ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "TPMSdata.xlsx"}
        );
      }
    }
  }
  // const [users, setUsers] = useState([])
  // const encodedValue = encodeURIComponent("Relative Turret Position Easting")
  // const fetchData = () => {
  //   fetch(`http://localhost:59476/api/Tpms`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       setUsers(data)
  //     })
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [])


















  //
  // ###################### //

  return (
    <div className='mt-12'>

          <Banner2 page="TPMS" statement={'Lastest Data Set Received:'} lastdate={lastfulldate}/>

        <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              All data is based on a full month up to current date.
              </p>
          </div>
        </div>
        <div className='flex flex-wrap w-full pt-0 pl-10 pr-10 justify-center align-top rounded-2xl'>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
              <div className='w-full justify-left'>
                <Header2 category="Hourly Resolution" title="EASTING vs NORTHING" thacolor={currentColor} />
              </div>
              <div className="w-full ">
                <TpmsWeeklyRadar />
              </div>
            </div>
          </div>
          <div className='flex flex-wrap w-6/12  '>
            <div className='w-full items-center rounded-2xl 
                            bg-white dark:text-white dark:bg-secondary-dark-bg
                            border-slate-600 dark:border-white border-4 ml-4 p-5'>
              <div className='w-full justify-left'>
                <Header2 category="Hourly Resolution" title="Heading Histogram" thacolor={currentColor}/>
              </div>
              <div className='w-full items-center align-center rounded-2xl 
                            bg-white dark:bg-black dark:text-gray-100 
                            border-slate-600 dark:border-white border-4 mr-4 p-5'>
                <div className="w-full align-center justify-center ">
                  <TpmsHistoHeading />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <div className="w-full ">
            <LineChartTPMS />
          </div>
          <br></br>
        </div>



        <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <Header2 title="Historic Tables" thacolor={currentColor} />
          <div className="w-full font-semibold text-xl text-center text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.
              </p>
          </div>
        </div>

        
    
    </div>
  )
}

export default TPMS
// <InfuxDB_Graph />
/*
LOG Table for Tpms
        <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
          <Header category="Logs Entries" title="Historic Log Table" />
          <div className="w-full">
            LOG TABLE GOES HERE
          </div>
        </div>



Grid Stuff for TPMS

<div className="w-full">
<GridComponent
  ref={gc => gridComp = gc}
  id="gridcomp"
  dataSource={users}
  allowPaging ={true}
  allowSorting
  allowExcelExport = {true}
  allowPdfExport
  toolbar={['Delete','Search','ExcelExport']}
  toolbarClick={toolbarBtnClick}
  allowFiltering={true}
  enableFilter={true}
  filterSettings={filterOption}
  editSettings={{ allowDeleting: true,
  allowEditing: true, allowFiltering: true}}
  width="auto">
  <ColumnsDirective>
    {stationGrid.map((item, index) =>(
      <ColumnDirective key={index} {...item} />
    ))}
  </ColumnsDirective>
  <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
</GridComponent>
</div>
<br></br>
<Header category="" title="High-Resolution" />
<div className="w-full">
  <InfuxDB_Grid />
</div>
 */
 