import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
  ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';

import { employeesData, employeesGrid,} from '../data/dummy';
import { Header } from '../components';


const Employees = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="Page" title="Employees" />
    <GridComponent
      id="gridcomp"
      dataSource={employeesData}
      allowPaging
      allowSorting
      allowExcelExport
      allowPdfExport
      toolbar={['Search']}
      width="auto">
      <ColumnsDirective>
        {employeesGrid.map((item, index) =>(
        <ColumnDirective key={index} {...item} />
        ))}
      </ColumnsDirective>
      <Inject services ={[ Resize, Sort, ContextMenu, Filter,Search, Page, Toolbar , ExcelExport]}/>
      </GridComponent>
  </div>
  )
}

export default Employees