import React , {useEffect,useState} from 'react';
import { useStateContext } from '../contexts/ContextProvider';

import ucimg from "../data/imgs/Sangomar_Digital_V1.png";
import { HeaderBanner }  from '../components';
import {Aws_controller} from '../components';
//            <img className="sofec_logo" src={sofeclogo}   alt="AIG_logo_SOFEC_white"/>
//<br></br>
// AWS PULL //
const sqlquery = 'SELECT MAX(date) FROM dev.public.zip_records;';
const thedata = await Aws_controller(sqlquery)

const Banner2 = ({page , statement, lastdate}) => {
    const [info, setInfo] = useState([])
    const { currentColor } = useStateContext();
    const backgroundlook = { 
        width: "98%",
        height: "92px",
        backgroundImage: "url(" + ucimg + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'}
    const backgroundlook1 = { 
            width: "25%",
            height: "92px",
            backgroundColor: currentColor,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'}
    const backgroundlook2 = { 
            width: "100%",
            height: "100px",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }
    const backgroundlook3 = { 
                width: "50%",
                height: "100px",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }

    
    useEffect(() =>{setInfo(thedata)}, []);

    const getTheDate = () =>{
        
        if (statement === 'Last 24-Hour dataset received:') {
            //.. AWS PULL ..//
            //Set AWS data
            
            let datapoints = [];
            //console.log(info)
            for (let i = 0; i < info.length; i++) {
                let point = {};
                point["date"] = info[i][0].stringValue;
                // push point
                datapoints.push(point);
                }
            //////////////////
            //console.log(datapoints)
            const findMaxNumber = (pointType) => {
                //const values = datapoints.map((point) => new Date(point[pointType]));
                //const maxx = moment(Math.max(...values)).format('YYYY-MM-DD');
                const values = datapoints.map((point) => point[pointType]);
                const maxx = values[0];
                //console.log('max', maxx)
                return maxx;};
            return findMaxNumber('date')
            ;
        }
        if (statement === 'Last Report:') {
            //////////////////
            let dadate = 'July 2024'
            return dadate
            ;
        } 
        if (statement === 'Lastest Data Set Received:') {
            //////////////////
            let dadate = lastdate
            return dadate
            ;
        } else {
            let dadate = '--/--/----';
            return dadate
            ;
        };   
    }
    
    const maxdate = getTheDate();

    return (
        <div className='mt-0'>
        <div className='m-0 md:m-10 md-10 md:p-0 rounded-3xl
            bg-slate-200 dark:bg-slate-200  dark:text-slate-700
            border-slate-600  dark:border-white border-2'>
            <div>  
                <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  rounded-3xl border-slate-600 dark:border-white border-2' style={backgroundlook2}>
                    <div className=" flex flex-wrap justify-between">
                        <div className='flex ml-2 mt-0 rounded-3xl justify-start items-center' style={backgroundlook3}>
                            <div>
                                <HeaderBanner title= {page}  thacolor= {currentColor} />
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-0 rounded-3xl justify-end align-top' style={backgroundlook1}>
                            <div className='flex flex-wrap mt-0 rounded-3xl justify-start items-center' style={backgroundlook}>
                            </div>
                        </div>
                    </div>           
                </div> 
            </div>
            <div className='flex flex-wrap ml-2'>
                <span className='text-lg font-semibold justify center align center ml-4'>
                    {statement}
                </span> 
                <span className='text-lg font-semibold text-green-500 justify-end ml-4 gap-3'>
                            {maxdate}
                </span>
            </div> 
        </div>
        </div>
    );
};

export default Banner2