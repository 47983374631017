import React , {useEffect,useState} from 'react';
import {AxisModel, BoxAndWhiskerSeries, Category, ChartComponent, Inject, SeriesCollectionDirective, SeriesDirective, Legend, Tooltip, Zoom} from'@syncfusion/ej2-react-charts';
import {Aws_controller} from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';

//const dadate = moment().format("YYYY-MM-DD")
//const dadate = "2022-04-10"
const statement = "SELECT * FROM dev.public.ds_alims WHERE date > current_timestamp - interval '30 day' and mean is not null  ORDER BY timestamp ASC;";


const dadata = await Aws_controller(statement)

const BoxWhiskerALIMS = () => {
    const [info, setInfo] = useState(dadata)
    const { currentMode } = useStateContext();

    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(dadata)}, [dadata]);
    //const result = info.length
    //console.log('The Output:', info)
    let lines=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["SERIAL"] = info[i][0].stringValue;
      point["TIMESTAMP"] = info[i][3].stringValue;
      point["ANCHOR_LEG"] = info[i][4].longValue;
      point["MEAN"] = info[i][6].doubleValue;
      point["MIN"] = info[i][7].doubleValue;
      point["MAX"] = info[i][8].doubleValue;
      lines.push(point);}
    //console.log('The Output4:', lines)
    //////////////////

    const primaryxAxis = { valueType: 'Category',
                            isIndexed: false };
    const primaryyAxis = { minimum: 0}
    
    // const [boxdata, setBoxdata] = useState([])
    // const fetchData = () => {
    //   fetch(`http://localhost:59476/api/ALIMS`)
    //     .then(response => {
    //       return response.json()
    //     })
    //     .then(data => {
    //         setBoxdata(data)
    //     })
    // }
    // useEffect(() => {
    //     fetchData()
    //     }, [])
    
    //{boxdata.filter(para => para.DATE.includes(dadate))} xName='ANCHOR_LEG' yName='MEAN' type='boxAndWhisker'/>
    
    //const dadate = moment().format("YYYY-MM-DD")
    //const dadate = "2022-04-10"
    
    //const lines = boxdata.filter(para => para.DATE.includes(dadate))
    const line1 = lines.filter(para => para.ANCHOR_LEG === 1).map((lines)=>(lines.MEAN))
    const line2 = lines.filter(para => para.ANCHOR_LEG === 2).map((lines)=>(lines.MEAN))
    const line3 = lines.filter(para => para.ANCHOR_LEG === 3).map((lines)=>(lines.MEAN))
    const line4 = lines.filter(para => para.ANCHOR_LEG === 4).map((lines)=>(lines.MEAN))
    const line5 = lines.filter(para => para.ANCHOR_LEG === 5).map((lines)=>(lines.MEAN))
    const line6 = lines.filter(para => para.ANCHOR_LEG === 6).map((lines)=>(lines.MEAN))
    const line7 = lines.filter(para => para.ANCHOR_LEG === 7).map((lines)=>(lines.MEAN))
    const line8 = lines.filter(para => para.ANCHOR_LEG === 8).map((lines)=>(lines.MEAN))
    const line9 = lines.filter(para => para.ANCHOR_LEG === 9).map((lines)=>(lines.MEAN))
    const datapoints = [{ x: "1",  y: line1 },
                        { x: "2",  y: line2 },
                        { x: "3",  y: line3 },
                        { x: "4",  y: line4 },
                        { x: "5",  y: line5 },
                        { x: "6",  y: line6 },
                        { x: "7",  y: line7 },
                        { x: "8",  y: line8 },
                        { x: "9",  y: line9 },
                        ]
    // ###################### //

  return (
    <div>
        <div>
            <ChartComponent  
              id='charts'
              primaryXAxis={primaryxAxis}
              primaryYAxis={primaryyAxis}
              enableMouseWheelZooming
              tooltip={{ enable: true }}
              zoomSettings={
                  {enableMouseWheelZooming: true,
                  enablePan: true,
                  enableScrollbar: true} }
              legendSettings={{ background:  '#fff' }}
              background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
>
                <Inject services={[BoxAndWhiskerSeries, Category,Legend, Tooltip, Zoom ]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={datapoints} xName='x' yName='y' type='BoxAndWhisker'/>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    </div>
  )
}

export default BoxWhiskerALIMS