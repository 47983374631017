import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, ScatterSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';

import { lineCustomSeries, LinePrimaryXAxisAll, LinePrimaryYAxisAll } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextStyle } from '@syncfusion/ej2/svg-base';
import { fontColor } from '@syncfusion/ej2/spreadsheet';
import {Aws_controller} from '../../components';

// AWS PULL //
const statement = "SELECT * FROM dev.public.east_north WHERE timestamp >= DATEADD(MONTH, -1, GETDATE()) ORDER BY timestamp ASC;"; //current_date
const thedata = await Aws_controller(statement)
//////////////

const TpmsWeeklyRadar = () => {
    const [param, setParam] = useState(1);
    const { currentMode } = useStateContext();
    const encodedValue = encodeURIComponent(1)
    const [info, setInfo] = useState(thedata)

    
    //.. AWS PULL ..//
    //Set AWS data
    //setInfo(thedata)
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
   // console.log('The Output:', info)
   let zero = [];
   let day = [];
   let week = []; 
   let month=[];
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["TIMESTAMP"] = info[i][0].stringValue;
      point["EAST"] = info[i][1].doubleValue;
      point["NORTH"] = info[i][2].doubleValue;
      // Write in math to add day/week/month to limit i values
      if (i < 24){
        day.push(point);}
      if (i < 168){
        week.push(point);}
      if (i < 744){
        month.push(point);}

      }
    let zpoint ={}
    zpoint["EAST"] = 0.0;
    zpoint["NORTH"] = 0.0;
    zero.push(zpoint)
    console.log('The OutputDAY:',  day)
    console.log('The OutputWEEK:', week)
    console.log('The OutputMONTH:', month)

    //////////////////
    



    const backgroundlook1 = { 
      backgroundColor: 'black'}
    const LinePrimaryXAxis = {
        labelFormat: '{value}',
        minimum: -10,
        maximum: 10,
        rangePadding: 'None',
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
      };
      
    const LinePrimaryYAxis = {
        labelFormat: '{value}',
        rangePadding: 'None',
        lineStyle: { width: 0 },
        minimum: -10,
        maximum: 10,
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
      };
    const lineCustomSeriesHome = [
        { dataSource: zero,
          xName: 'EAST',
          yName: 'NORTH',
          name: 'Zero',
          type: 'Scatter',
          width: 2,
          fill: 'blue',
          marker: { visible: true, width: 10, height: 14, fill:'blue' , shape: 'Diamond' }
           }
      ]; 
      const lineCustomSeriesDay = [
        { dataSource: day,
          xName: 'EAST',
          yName: 'NORTH',
          name: 'Day',
          type: 'Scatter',
          width: 2,
          fill: '#01fb01',
          marker: { visible: true, width: 7.5, height: 7.5, fill:'green' , shape: 'Circle' }
           }
      ];
      const lineCustomSeriesWeek = [
        { dataSource: week,
          xName: 'EAST',
          yName: 'NORTH',
          name: 'Week',
          type: 'Scatter',
          width: 2,
          fill: 'green',
          marker: { visible: true, width: 7.5, height: 7.5, fill:'green' , shape: 'Circle' }
           }
      ];
      const lineCustomSeriesMonth = [
        { dataSource: month,
          xName: 'EAST',
          yName: 'NORTH',
          name: 'Month',
          type: 'Scatter',
          width: 2,
          fill: '#618e61',
          marker: { visible: true, width: 7.5, height: 7.5, fill:'green' , shape: 'Circle' }
           }
      ];  
  return (
    <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                    bg-white dark:bg-black
                  border-slate-600 dark:border-white border-4 ">
        <ChartComponent
        id="tpms-radar"
        height="650px"
        width='650px'
        primaryXAxis={LinePrimaryXAxis}
        primaryYAxis={LinePrimaryYAxis}
        chartArea={{ border: { width: 0 } }}
        tooltip={{ enable: true }}
        enableMouseWheelZooming
        zoomSettings={
            {enableMouseWheelZooming: true,
            enablePan: true,
            enableScrollbar: true}
        }
        background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
        legendSettings={{ background: currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff' ,
                          textStyle: [fontColor = ['green']]}}
        >
        <Inject services={[ScatterSeries, DateTime, Legend, Tooltip, Zoom ]} />
        <SeriesCollectionDirective>
            {lineCustomSeriesMonth.map((item, index) => <SeriesDirective key={index} {...item} />)}
            {lineCustomSeriesWeek.map((item, index) => <SeriesDirective key={index} {...item} />)}
            {lineCustomSeriesDay.map((item, index) => <SeriesDirective key={index} {...item} />)}
            {lineCustomSeriesHome.map((item, index) => <SeriesDirective key={index} {...item} />)}
            
            
            

        </SeriesCollectionDirective>

        </ChartComponent>
    </div>
  )
}

export default TpmsWeeklyRadar