import React , {useEffect,useState} from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Aws_controller} from '../index';
import { Gauge} from '../index';

// AWS BANNER PULL //
const H2Oquery ="SELECT TOP 8 h2o, location1, sample_date From dev.public.grease ORDER BY sample_date DESC;";
const h2odata = await Aws_controller(H2Oquery)

//console.log('asdasdasd',h2odata)
//const h2ovalue = h2odata[0][0].doubleValue

const Ironquery = "SELECT TOP 8 iron, location1, sample_date From dev.public.grease ORDER BY sample_date DESC;";
const irondata = await Aws_controller(Ironquery)


const Coppquery = "SELECT TOP 8 copper, location1, sample_date From dev.public.grease ORDER BY sample_date DESC;";
const coppdata = await Aws_controller(Coppquery)


const PQquery = "SELECT TOP 8 pq_index, location1, sample_date From dev.public.grease ORDER BY sample_date DESC;";
const pqdata = await Aws_controller(PQquery)

// ################ //


const GaugeCluster = ({loc}) => { 
  ///////////////////////////////////////
  const [h2o, setH2o] = useState(h2odata)
  const [iron, setIron] = useState(irondata)
  const [copp, setCopp] = useState(coppdata)
  const [pq, setPQ] = useState(pqdata)
  ///////////////////////////////////////
  let H2opoints=[]
  for (let i = 0; i < h2o.length; i++) {
    let Hpoint = {};
    Hpoint['H2O'] = h2o[i][0].doubleValue;
    Hpoint["POSITION"] = h2o[i][1].stringValue;
    Hpoint["TIMESTAMP"] = h2o[i][2].stringValue;
    H2opoints.push(Hpoint);}
    //console.log('The OutputH2o:', H2opoints)
  const h2ovalues = H2opoints.filter(para => para.POSITION.includes(loc));
  //console.log('asdasdasd1',h2ovalues)
  const h2ovalue = h2ovalues[0].H2O
  //console.log('asdasdasd2',h2ovalue)
  ///////////////////////////////////////
  let Ironpoints=[]
  for (let i = 0; i < iron.length; i++) {
    let Ipoint = {};
    Ipoint['IRON'] = iron[i][0].doubleValue;
    Ipoint["POSITION"] = iron[i][1].stringValue;
    Ipoint["TIMESTAMP"] = iron[i][2].stringValue;
    Ironpoints.push(Ipoint);}
    //console.log('The OutputIron:', Ironpoints)
  const ironvalues = Ironpoints.filter(para => para.POSITION.includes(loc));
  //console.log('asdasdasd1',ironvalues)
  const ironvalue = ironvalues[0].IRON
  //console.log('asdasdasd2',ironvalue)
  ///////////////////////////////////////
  let Copppoints=[]
  for (let i = 0; i < copp.length; i++) {
    let Cpoint = {};
    Cpoint['COPPER'] = copp[i][0].doubleValue;
    Cpoint["POSITION"] = copp[i][1].stringValue;
    Cpoint["TIMESTAMP"] = copp[i][2].stringValue;
    Copppoints.push(Cpoint);}
    //console.log('The OutputIron:', Copppoints)
  const coppvalues = Copppoints.filter(para => para.POSITION.includes(loc));
  //console.log('asdasdasd1',coppvalues)
  const coppvalue = coppvalues[0].COPPER
  //console.log('asdasdasd2',coppvalue)
  ///////////////////////////////////////
  let PQpoints=[]
  for (let i = 0; i < pq.length; i++) {
    let Ppoint = {};
    Ppoint['PQ'] = pq[i][0].longValue;
    Ppoint["POSITION"] = pq[i][1].stringValue;
    Ppoint["TIMESTAMP"] = pq[i][2].stringValue;
    PQpoints.push(Ppoint);}
    //console.log('The OutputPQ:', PQpoints)
  const pqvalues = PQpoints.filter(para => para.POSITION.includes(loc));
  //console.log('asdasdasd1',pqvalues)
  const pqvalue = pqvalues[0].PQ
  //console.log('asdasdasd2',pqvalue)
  ///////////////////////////////////////
  return (
    <div>
      <div className='flex flex-wrap w-full pt-0 justify-center align-top rounded-2xl'>
        
        <div className='flex flex-wrap w-3/12 justify-center '>
          <div style={{ justifyContent: 'center', textAlign: "center" }}>
              <h2>H2O</h2>
              <Gauge theid="1" val ={h2ovalue} unit = "ppm" maxval = {5000} ge={1000} ye={2000} or={3000} rd={5000}/>
          </div>
        </div>

        <div className='flex flex-wrap w-3/12 justify-center  '>
          <div style={{ justifyContent: 'center', textAlign: "center" }}>
              <h2>Iron(Fe)</h2>
              <Gauge theid="1" val = {ironvalue} unit = "ppm" maxval = {22000} ge={1000} ye={10000} rd={20000}/>
          </div>
        </div>

        <div className='flex flex-wrap w-3/12 justify-center '>
          <div style={{ justifyContent: 'center', textAlign: "center" }}>
              <h2>Copper(Cu)</h2>
              <Gauge theid="1" val = {coppvalue} unit = "ppm" maxval = {75} ge={0} ye={25} or={50} rd={75}/>
          </div>
        </div>

        <div className='flex flex-wrap w-3/12  justify-center'>
          <div style={{ justifyContent: 'center', textAlign: "center" }}>
              <h2>PQ Index</h2>
              <Gauge theid="1" val = {pqvalue} unit="" maxval = {2500} ge={0} ye={500} or={1500} rd={2500}/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default GaugeCluster