import React from 'react';
import {Banner} from '../../components';
import { LineChartSwivelDynamic } from '../../components/index'
import { useStateContext } from '../../contexts/ContextProvider';
import {SwivelHomeData,SwivelTempData } from '../../data/dummy';
import { Header,Header2, HomeSwivelTable, SwivelAlertTable,SwivelFaultBoard} from '../../components';
//Swivel Board Stuff
import BannerSwivelList from '../../components/BannerSwivelList';

const GasExport = () => {
  const { currentColor } = useStateContext();
  return (
    <div className='mt-12'>

      <Banner page="Gas Export Dashboard"/>
      <div>
        <BannerSwivelList />
      </div>

      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4
                        m-4 md:m-10 mt-24 p-10 rounded-3xl">
        <div className="flex justify-between">
          <Header2 title="ALERT BOARD"  thacolor= {currentColor} />
        </div>

        <div>
          <SwivelAlertTable datadata={SwivelTempData}/>
        </div>
        <div className="flex justify-between">
          <Header2 title="FAULT BOARD"  thacolor= {currentColor} />
        </div>

        <div className="w-full">
          <SwivelFaultBoard datadata={SwivelHomeData}/>
        </div>
      </div>



      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4
                        m-4 md:m-10 mt-24 p-10 rounded-3xl">
        <div className="items-center">
          <div className="w-full">
           <LineChartSwivelDynamic sysss = {'GAS_EXPORT_SWIVEL'} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default GasExport