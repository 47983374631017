import React, {useState} from 'react';
// styling
import './SubMenu.css';
import { swivelmenu } from '../data/dummy';
import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";
  
  function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

const SubSwivelMenu = props => {
    // conditionally render dropdown affect based on this boolean
    const [openMenu, setOpenMenu] = useState(false)

    // parameter num corresponds to .open-# classes
    // is assigned when Menu clicked triggering animated dropdown
    const setClassNames = num => {
        const classArr = ["m-item"];
        if (openMenu) classArr.push(`open-${num}`)
        return classArr.join(' ')
    }

    // takes route string as parameter
    const pushToRoute = route => {
        props.history.push(route)
        setOpenMenu(false)
    }
    // render each menu item after Menu button clicked
    const renderMenuItems = data => {    
        const colorArr = ["#9b5de5", "#f15bb5", "#00BBF9"];

        let colorCounter = -1;
        return swivelmenu.map((item, index) => {

            // if counter is over 2, resets to 0
            // for colorArr bracket notation to get sequence of colors
            colorCounter < 2 ? colorCounter++ : colorCounter = 0

            // dynamic styles for each menu item 
            const itemStyle = {
                "top": `${index * 1.8}em`,
                "backgroundColor": colorArr[colorCounter]
            }

            return (
                <div className="m-item"
                    key={item.id}
                    style={openMenu ? itemStyle : null}
                    onClick={() => pushToRoute(item.route)}>
                    {item.name}
                </div>
            )
        })
    };

    return (
        <div className="Menu">
            <div className={"m-item m-logo"}
                onClick={() => setOpenMenu(!openMenu)}>
                Swivel
            </div>

            {renderMenuItems(swivelmenu)}

        </div>
  );
}

export default withRouter(SubSwivelMenu);