import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

const SecureRoute = ({ children }) => {
  const { authState } = useOktaAuth();

  if (!authState) return <div>Loading...</div>;
  
  return authState.isAuthenticated ? children : <Navigate to="/login" />;
};

export default SecureRoute;