import React, {useEffect,useState} from 'react'
import "../styles.css";
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page, ExcelExport,  Inject } from '@syncfusion/ej2-react-grids';
import {  LubeAlertGrid } from '../data/dummy';
import { Bulbb} from '../components';
import {Aws_controller} from '../components';

// AWS PULL //
const statement = 'SELECT * FROM dev.public.log_lube_summary;';
const thedata = await Aws_controller(statement)
//////////////

const LubeAlertTable = () => {
    const [dadata, setDadata] = useState([])
    const [info, setInfo] = useState([])
    // Take subsystem and system out and threshold
    //    //.. AWS PULL ..//
    // //Set AWS data
    useEffect(() =>{setInfo(thedata)}, []);
    // //const result = info.length
    //console.log('The OutputALERT1:', info)
    let datapoints = [];// asdasd
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["PARAMETER"] = info[i][0].stringValue;
        point["CS"] = info[i][1].stringValue;
        point["LA"] = info[i][2].stringValue;
        point["AAS"] = info[i][3].stingValue;
        point["AP30"] = info[i][4].longValue;
        point["APA"] = info[i][5].longValue;
        if (point["CS"] == 'ALARM' ||  point["CS"] == 'OFF' ){
            point["bulbcolor"] = 'red'
            }
        else{
            point["bulbcolor"] = 'green'
            }
        if ( point["CS"] == 'OFF' && point["PARAMETER"] == 'System on MANUAL Mode'){
            point["bulbcolor"] = 'yellow'
            }
        if (point["LA"] == undefined ){
            point["LA"] = '-'
            }
        if (point["AAS"] == undefined ){
            point["AAS"] = '-'
            }
        // push point
        datapoints.push(point);
        }
      //console.log('The OutputAlert2:',  datapoints)
  
      //////////////////


  return (
    <div>
        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-white border-4 rounded-3xl">
            <GridComponent
                id="gridcomp"
                dataSource={datapoints}
                width="auto"
                gridLines='Both'
                >
                <ColumnsDirective>
                    {LubeAlertGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
                ))}
                </ColumnsDirective>
                <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
            </GridComponent>
        </div>
    </div>
  )
}

export default LubeAlertTable

// const fetchData = () => {
//     fetch(`http://localhost:59476/api/LubricationLogsController1`)
//         .then(response => {
//         return response.json()
//         })
//         .then(data => {
//         setDadata(data)
//         })
//     }
//     useEffect(() => {
//         fetchData()
//         }, [])