import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { links,swivelmenu } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import sofeclogo from "../data/imgs/AIG_logo_SOFEC_white.png";

import {HeaderSideBar }  from '../components';

import SubSwivelMenu from './SubMenu'
import './SubMenu.css'



const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize , currentColor
  } = useStateContext();

  const options = [
    'one', 'two', 'three'
  ];
  const defaultOption = options[0];
  
  // For SubMenu, conditionally render dropdown affect based on this boolean
  const [openMenu, setOpenMenu] = useState(false)
  // parameter num corresponds to .open-# classes
  // is assigned when Menu clicked triggering animated dropdown
  const setClassNames = num => {
      const classArr = ["m-item"];
      if (openMenu) classArr.push(`open-${num}`)
      return classArr.join(' ')
  };
  // render each menu item after Menu button clicked
  const renderMenuItems = data => {   

    let colorCounter = -1;
    return swivelmenu.map((item, index) => {
        // dynamic styles for each menu item 
        const itemStyle = {
            "top": `${index * 1.8}em`,
            "backgroundColor": "#9b5de5"
        }

        return (
            <div className="m-item"
                key={item.id}
                style={openMenu ? "text-gray-400 m-3 mt-4 uppercase" : null}
                onClick={() => handleCloseSideBar}>
                {item.name}
            </div>
        )
    })
  };

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded lg text-white text-md m-2';
  
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  //<span>SANGOMAR</span>

  return (
    <div className='ml-1 h-screen
    md:overflow-hidden overflow-auto
    md:hover:overflow-auto pb-5'>
      
      {activeMenu && (<>
        <div className='flex justify-between
        items-center  bg-slate-600 dark:bg-slate-600'>
          <Link to="/" onClick={handleCloseSideBar}
          className="items-center gap-3 ml-3 mt-4 flex text-xl 
          font-extrabold tracking-tight dark:text-white text-slate-900">
          <img className="aig_logo" src={sofeclogo}  alt="AIG_logo_1" />
          </Link>
          

          <TooltipComponent content="Menu"
          position='BottomCenter'>
            <button type="button"
              onClick={() => setActiveMenu(
                (prevActiveMenu) => !prevActiveMenu)}
              className="text-xl rounded full
              p-3 hover:bg-light-gray mt-4 block">
              <MdOutlineCancel/>
            </button>
          </TooltipComponent>
          
        </div>
        <div className='flex justify-between
              items-center  bg-slate-600 dark:bg-slate-600 '>
              <div className='ml-6'>
                <HeaderSideBar category="Woodside Sangomar FPSO"  />
              </div>
        </div>
        

        <div className='mt-10'>
          {links.map((item)=> (
            <div key={item.title}>
              <p className="text-gray-400 m-3
              mt-4 uppercase">
                {item.title}
                </p>
                
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}

                    style={({ isActive }) =>({
                      backgroundColor:isActive ?
                      currentColor: ''
                    })}

                    className={({ isActive }) => 
                    isActive ? activeLink : normalLink}
                    >
                    {link.icon}
                    <span className='uppercase'>
                      {link.alt}
                    </span>
                  </NavLink> 
                  ))}
              </div>
              
              ))}
              

          </div>
        </>
      )}
  </div>
  );
};
// <SubSwivelMenu/>
export default Sidebar