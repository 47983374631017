import React from 'react'
//"text-gray-400"
const HeaderBanner = ({ category, title, thacolor }) => {
    return (
      <div>
        <p className="text-white">
          {category}
        </p>
        <p className='text-3xl font-extrabold
        tracking-tight text-slate-900' style={{color:thacolor}}>{title}</p>
      </div>
    )
  }
  
  export default HeaderBanner