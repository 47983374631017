import React, {useEffect,useState} from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, employeesGrid , SwivelAlertGrid, SwivelFBTestData} from '../data/dummy';
import { Bulbb} from '.';
import '../Grids.css'
import {Aws_controller} from '../components';

// AWS PULL //
const statement = "SELECT * FROM (SELECT COUNT(*) FROM dev.public.log_lube_summary Where current_status = 'NORMAL' ) as norms, (SELECT COUNT(*) FROM dev.public.log_lube_summary Where current_status = 'ALARM') as alarms, (SELECT TOP 1 mean FROM dev.public.ds_lube WHERE parameter = 'GREASE LEVEL MAIN RESERVOIR' ORDER BY timestamp DESC) as grease, (SELECT TOP 1 timestamp FROM dev.public.ds_lube WHERE parameter = 'GREASE LEVEL MAIN RESERVOIR' ORDER BY timestamp DESC) as time";
const thedata = await Aws_controller(statement)
//////////////


const HomeLubeTable = () => {
    const [info, setInfo] = useState([])
    // Take subsystem and system out and threshold
    //    //.. AWS PULL ..//
    // //Set AWS data
    useEffect(() =>{setInfo(thedata)}, []);
    // //const result = info.length
    //console.log('The OutputLubeDash:', info)
    let datapoints = [];// asdasd
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["norm"] = info[i][0].longValue;
        point["alarm"] = info[i][1].longValue;
        point["grease"] = info[i][2].doubleValue;
        point["ts"] = info[i][3].stingValue;
        // push point
        datapoints.push(point);
        }
    //console.log('The lubestuff:',  datapoints)

  return (
    <div>
        <>
        {datapoints.map((point, index) => (
            <div className="bg-slate-200 dark:text-gray-200 dark:bg-slate-600 m-1 p-4 rounded-2xl md:w-480  ">
                <div className=' dark:text-gray-200  mt-1 flex-wrap justify-center '>
                    <div className= 'mt-1 flex flex-wrap justify-center' >
                        <p className="font-semibold text-3xl text-yellow-400">Lubrication System Snapshot</p>                     
                    </div>
                    <div className= 'mt-1 mb-3 flex flex-wrap justify-center' >
                        <p className="font-semibold text-xl text-yellow-400">{point.ts}</p>
                    </div>
                </div>
                <div className='bg-slate-600 dark:text-gray-200 dark:bg-secondary-dark-bg  border-4 mt-1 p-10 flex justify-evenly rounded-3xl '>
                
                    <div className='bg-slate-600 rounded-xl border-4  border-gray-900 flex-wrap justify-center mr-4 p-1'>
                        <div className='flex flex-wrap justify-center m-1 p-1'>
                            <p>
                                <span className='text-2xl text-blue-400 font-bold'>NORMAL mode</span>
                            </p>
                        </div>
                        <div className=' rounded-xl  flex-wrap justify-center m-1 p-10'>

                            <div className='flex flex-wrap justify-center p-2'>
                                <p>
                                    <span className='text-6xl font-semibold text-green-400'>{point.norm}</span>
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-center'>
                                <p><span className='font-normal'>20 parameters monitored</span></p>
                        </div>
                    </div>
                    <div className='bg-slate-600 rounded-xl border-4  border-gray-900 flex-wrap justify-center p-1'>
                        <div className='flex flex-wrap justify-center m-1 p-1'>
                            <p>
                                <span className='text-2xl text-blue-400 font-bold'>Active Alarms</span>
                            </p>
                        </div>
                        <div className=' rounded-xl  flex-wrap justify-center m-1 p-10'>
                            <div className='flex flex-wrap justify-center p-2'>
                                <p>
                                    <span className='text-6xl font-semibold text-red-400'>{point.alarm}</span>
                                </p>
                            </div>
                        </div> 
                        <div className='flex flex-wrap justify-center'>
                            <p><span className='font-normal'>20 parameters monitored</span></p>
                        </div>
                    </div>
                    <div className='bg-slate-600 rounded-xl border-4  border-gray-900 flex-wrap justify-center ml-4 p-1'>
                        <div className='flex flex-wrap justify-center m-1 p-1'>
                            <p>
                                <span className='text-2xl text-blue-400 font-bold'>Main Reservoir Fill Level</span>
                            </p>
                        </div>
                        <div className=' rounded-xl  flex-wrap justify-center m-1 p-10'>
                            <div className='flex flex-wrap justify-center p-2'>
                                <p>
                                    <span className='text-6xl font-semibold text-green-400'>{point.grease}%</span>
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-center'>
                            <p>
                                <span className='font-normal'>-2.0%</span>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        ))}
        </>
    </div>
  )
}

export default HomeLubeTable