import React from 'react';
import "../styles.css";
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort,
    ContextMenu, Filter, Page,Search,Toolbar, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, SwivelAlertTableGrid , HomeSSGrid, SwivelTempData} from '../data/dummy';
import { Bulbb} from '../components';

const SwivelAlertTable  = ({datadata}) => {
  return (
    <div>
        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-white border-4 rounded-3xl">
            <GridComponent
                id="gridcomp"
                dataSource={SwivelTempData}
                width="auto"
                gridLines='Both'
                >
                <ColumnsDirective>
                    {SwivelAlertTableGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
                ))}
                </ColumnsDirective>
                <Inject services ={[ Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
            </GridComponent>
        </div>
    </div>
  )
}

export default SwivelAlertTable