import React, {useEffect,useState} from 'react'
import "../styles.css";
import { GridComponent, ColumnsDirective, ColumnDirective, Resize,
    ContextMenu, Filter, Page,Search,Toolbar, Sort,ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { employeesData, employeesGrid , LubeAlertGrid ,logGrid, HomeSSData} from '../data/dummy';
import { Bulbb} from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import {Aws_controller} from '../components';

// AWS PULL //
const statement = "SELECT * FROM dev.public.logs WHERE (DATE >= DATEADD(WEEK, -1, (SELECT  max(date) FROM dev.public.logs)) AND (_system = 'LVEOSR' OR _system = 'HVESR')) ORDER BY DATE ASC;";
const thedata = await Aws_controller(statement)
//console.log('The OutputSR1:', thedata)
//////////////

const SlipRingLogTable = () => {
    const { currentMode } = useStateContext();
    const [dadata, setDadata] = useState([])
    const [info, setInfo] = useState([])
    // Take subsystem and system out and threshold
    //    //.. AWS PULL ..//
    // //Set AWS data
    useEffect(() =>{setInfo(thedata)}, []);
    // //const result = info.length
    //console.log('The OutputSRrr2:', info)
    let datapoints = [];// asdasd
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["DATE"] = info[i][0].stringValue;
        point["TIME"] = info[i][1].stringValue;
        point["CHANNEL"] = info[i][4].stringValue;
        point["VALUE"] = info[i][5].doubleValue;
        point["CODE"] = info[i][6].stringValue;
        point["TEXT"] = info[i][7].stringValue;
        // push point
        datapoints.push(point);
        }
      //console.log('The OutputSR2:',  datapoints)
  
      //////////////////

    //////////////////////
    // Grid -stuff
    const pageSettings = { pageSize: 20 }
  return (
    <div>
        <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-white border-4 rounded-3xl">
            <GridComponent
                id="gridcomp"
                dataSource={datapoints}
                allowPaging={true} 
                pageSettings={pageSettings}
                width="auto"
                gridLines='Both'
                >
                <ColumnsDirective>
                    {logGrid.map((item, index) =>(
                <ColumnDirective key={index} {...item} />
                ))}
                </ColumnsDirective>
                <Inject services ={[Toolbar, Resize, Sort, ContextMenu, Filter, Page,  ExcelExport]}/>
            </GridComponent>
        </div>
    </div>
  )
}

export default SlipRingLogTable