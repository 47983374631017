import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Internationalization } from '@syncfusion/ej2-base';
import { lineCustomSeries, LinePrimaryXAxisGREASE, LinePrimaryYAxisH2O } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { Header, Header2} from '..';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import {Aws_controller} from '..';;
// AWS PULL //
const statement = "SELECT iron, location1,sample_date From dev.public.grease order by sample_date ASC";
const thedata = await Aws_controller(statement)
//////////////


const LineChartGrease_Iron = () => {
    const [param, setParam] = useState(1);
    const { currentColor } = useStateContext();
    const { currentMode } = useStateContext();
    const encodedValue = encodeURIComponent(1)
    const [info, setInfo] = useState(thedata)
    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
    console.log('The Output:', info)
    let points=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["VALUE"] = info[i][0].doubleValue;
      point["LOCATION"] = info[i][1].stringValue;
      point["TIMESTAMP"] = info[i][2].stringValue;
      points.push(point);}
    console.log('The Output7123:', points)
    //////////////////
    const lineCustomSeries = [
        { dataSource: points.filter(para => para.LOCATION.includes("AFT")),
          xName: 'TIMESTAMP',
          yName: 'VALUE',
          name: 'AFT',
          width: '2',
          fill: 'red',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'red' }},
          type: 'Line' },
    ];
    const lineCustomSeries1 = [
          { dataSource: points.filter(para => para.LOCATION.includes("FWD")),
          xName: 'TIMESTAMP',
          yName: 'VALUE',
          name: 'FWD',
          width: '2',
          fill: 'blue',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'blue' }},
          type: 'Line' },
    ];
    const lineCustomSeries2 = [
          { dataSource: points.filter(para => para.LOCATION.includes("STBD")),
          xName: 'TIMESTAMP',
          yName: 'VALUE',
          name: 'STBD',
          width: '2',
          fill: 'yellow',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'yellow' }},
          type: 'Line' },
    ];
    const lineCustomSeries3 = [
          { dataSource: points.filter(para => para.LOCATION.includes("Port")),
          xName: 'TIMESTAMP',
          yName: 'VALUE',
          name: 'PORT',
          width: '2',
          fill: 'green',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'green' }},
          type: 'Line' },
    ];
    const lineCustomSeries4 = [
          { dataSource: points.filter(para => para.LOCATION.includes("Drum")),
          xName: 'TIMESTAMP',
          yName: 'VALUE',
          name: 'DRUM',
          width: '2',
          color: 'white',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'white' }},
          type: 'Line' },
    ];
    console.log('Outputh20:' , lineCustomSeries1)
    //////////////////////
    const tooltipRender = (args) => {
        let intl = new Internationalization();
        let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' });
        let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
        args.text =  `${XformattedString} : ${YformattedString}m`; 
    };
  return (
<div>
    {/* <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
        <div className='flex flex-wrap w-3/12 justify-left items-center rounded-2xl pl-0'>
          <Header2 category="30-day Trending" title="Hourly Resolution" thacolor={currentColor} />
        </div>
    </div> */}
    
    <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                  bg-white dark:bg-black
                border-slate-600 dark:border-white border-4 ">
            <div className='w-full'>
                    <ChartComponent
                    id="iron-chart"
                    height="420px"
                    primaryXAxis={LinePrimaryXAxisGREASE}
                    primaryYAxis={LinePrimaryYAxisH2O}
                    chartArea={{ border: { width: 0 } }}
                    tooltipRender={tooltipRender}
                    tooltip={{ enable: true }}
                    enableMouseWheelZooming
                    zoomSettings={
                        {enableMouseWheelZooming: true,
                        enablePan: true,
                        enableScrollbar: true}
                    }
                    background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
                    legendSettings={{ background: 'white' }}
                    >
                    <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
                    <SeriesCollectionDirective>
                        {lineCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries1.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries3.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        {lineCustomSeries4.map((item, index) => <SeriesDirective key={index} {...item} />)}
                    </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
</div>
);
};

export default LineChartGrease_Iron