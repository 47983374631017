import React, {useEffect,useState} from 'react';
import * as ReactDOM from 'react-dom';
import { useStateContext } from '../contexts/ContextProvider';

import { Banner2} from '../components';
import { Header,Header2} from '../components';

import { LineChartICSS } from '../components/index';
import {Aws_controller} from '../components';

// Grid-Table Stuff
import { icssGrid} from '../data/dummy';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection,Search,
  Inject, Edit, Toolbar, Sort, Filter,  FilterSettingsModel,
  ExcelExport, ExcelExportProperties } from '@syncfusion/ej2-react-grids';
import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';


let gridComp = GridComponent | null; 
// AWS BANNER PULL //
const sqlquery = "SELECT DATE(DATE_ADD('s',AVG(EXTRACT(EPOCH FROM extracted_timestamps.timestamp)),'1970-01-01')) AS average_date FROM (SELECT timestamp FROM dev.public.ds_icss ORDER BY timestamp DESC LIMIT 48) AS extracted_timestamps;";
const thedata = await Aws_controller(sqlquery)
const lastfulldate = thedata[0][0].stringValue
// ################ //
const ICSS = () => {
  const { currentColor } = useStateContext();
  // SQL GRID STUFF //
  const filterOption = {
    ignoreAccent: true,
    type:'Excel'
  }
  const toolbarSettings = ["ExcelExport"];
  const toolbarBtnClick = (args) => {
    if(gridComp){
      if(args.item.id.includes("excelexport")){
        gridComp.excelExport(
          {fileName : "ICSSdata.xlsx"}
        );
      };
    };
  };

  // ###################### //
  return (
    <div className='mt-12'>
      <Banner2 page="ICSS Dashboard" statement={'Lastest Data Set Received:'}  lastdate={lastfulldate}/>

      <div className="m-4 md:m-10 mt-24 p-10 rounded-3xl
                     bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                     border-slate-600 dark:border-white border-4">
        <Header2 category="30-day Trending" title="ICSS Hourly Resolution" thacolor={currentColor} />
        <div className="w-full">
          <LineChartICSS />
        </div>
      </div>

      <div className="m-4 md:m-10 mt-24 p-8  rounded-3xl
                       bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
          <Header2 title="Historic Tables" thacolor={currentColor} />
          <div className="w-full font-semibold text-xl text-end text-yellow-400 justify-center items-center">
              <p>
              Past data, and high-resolution data (2Hz) is available. Contact SOFEC Asset Integrity Group for specific enquiries.”
              </p>
          </div>
        </div>


    </div>
  );
};

export default ICSS

/*
Grid Stuff for Swivel
  <div className="w-full">
    <GridComponent
      ref={gc => gridComp = gc}
      id="gridcomp"
      dataSource={users}
      allowPaging ={true}
      allowSorting
      allowExcelExport = {true}
      allowPdfExport
      toolbar={['Delete','Search','ExcelExport']}
      toolbarClick={toolbarBtnClick}
      allowFiltering={true}
      enableFilter={true}
      filterSettings={filterOption}
      editSettings={{ allowDeleting: true,
      allowEditing: true, allowFiltering: true}}
      width="auto">
      <ColumnsDirective>
        {icssGrid.map((item, index) =>(
          <ColumnDirective key={index} {...item} />
        ))}
      </ColumnsDirective>
      <Inject services ={[ Sort, Filter, Page, Search, Toolbar ,Selection,Edit, ExcelExport]}/>
    </GridComponent>
  </div>

*/