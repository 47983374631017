import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Header2} from '../../components';
import { lineCustomSeries, LinePrimaryXAxis2, LinePrimaryXAxisAll, LinePrimaryYAxisAll } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';

// AWS PULL //
const statement = 'SELECT * FROM dev.public.ds_swivel WHERE timestamp >= DATEADD(DAY, -1, (SELECT  max(date) FROM dev.public.ds_swivel)) ORDER BY timestamp ASC;';
const thedata = await Aws_controller(statement)
//////////////


const systParms = ['GAS_INJECTION_SWIVEL','FLOWLINE_CIRCULATION_SWIVEL','HPU_1','GAS_LIFT_SWIVEL',
                  'GAS_EXPORT_SWIVEL','PRODUCTION_2_SWIVEL','TEST_SWIVEL','HPU_2','PRODUCTION_1_SWIVEL',
                  'HVESR_US_LVEOSR','DEPRESSURIZATION_2_SWIVEL','DEPRESSURIZATION_1_SWIVEL','LEAK_RECOVERY',
                  'WATER_INJECTION_SWIVEL'];

const paramParms = ['Leak Tank Pressure','Pressure OBS secondary bottom','Swivel Torque (mean value)',
                    'Pressure Downstream','Leak Tank Level','Pressure OBS inlet','Heat Tracing Upstream',
                    'Pressure OBS primary bottom','Oil Tank Level','HVESR Swivel Torque (mean value)',
                    'Pressure OBS primary top','Pressure OBS secondary top','Pressure Upstream','Heat Tracing',
                    'LVEOSR Swivel Torque (mean value)',  'Heat Tracing Downstream'];

const LineChartSwivel = () => {
  const [syst, setSyst] = useState('GAS_INJECTION_SWIVEL');
  const parmfilter = (dasyst, daparm ,dadata) => dadata.filter(dasyst => dasyst.SYSTEM === dasyst).filter(dasystparm => dasystparm.PARAMETER === daparm);

  const [parameter, setParameter] = useState('Pressure OBS inlet');
  const { currentColor } = useStateContext();
  const { currentMode } = useStateContext();
  const [users, setUsers] = useState([])
  const encodedValue = encodeURIComponent('GAS_INJECTION_SWIVEL')
  const [info, setInfo] = useState([])

  //.. AWS PULL ..//
  //Set AWS data
  useEffect(() =>{setInfo(thedata)}, []);
  //const result = info.length
  //console.log('The OutputICss:', info)
  let datapoints = [];
  for (let i = 0; i < info.length; i++) {
    let point = {};
    point["TIMESTAMP"] = info[i][3].stringValue;
    point["SYSTEM"] = info[i][4].stringValue;
    point["PARAMETER"] = info[i][5].stringValue;
    point["MEAN"] = info[i][7].doubleValue;
    point["MIN"] = info[i][8].doubleValue;
    point["MAX"] = info[i][9].doubleValue;
    point["STDV"] = info[i][10].doubleValue;
    // push point
    datapoints.push(point);
    }
  console.log('The OutputSwivvy2:',  datapoints)

  //////////////////

  //////////////////////

  
  const lineCustomSeries2 = [
    { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MEAN',
      name: 'Mean',
      width: '2',
      fill: 'grey',
      marker: { visible: true, width: 3, height: 3,fill:'black',
      border: { width: 2, color: 'grey' }},
      type: 'Line' },
      { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MIN',
      name: 'Min',
      width: '3',
      fill: 'bg-white',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
              border: { width: 2, color: 'Red' }},
      type: 'Line' },
      { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MAX',
      name: 'Max',
      width: '0',
      color: 'green',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
              border: { width: 2, color: 'green' }},
      type: 'Line' },
  ];

  return (
    <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
      <div className='flex flex-wrap w-3/12 justify-left items-center rounded-2xl pl-0'>
        <Header2 category="30-day Trending" title="Hourly Resolution" thacolor= {currentColor}  />
      </div>
      <div className="flex flex-wrap w-9/12 justify-center items-center align-top">
        <div className="m-0 md:m-2 p-1 w-full bg-slate-500 rounded-2xl">
          <DropDownListComponent id="ddlelement" 
          dataSource={systParms} 
          placeholder= 'GAS_INJECTION_SWIVEL'
          onChange={e => {setSyst(e.target.value)}}
          />
        </div>
        <div className="m-0 md:m-2 mt-24 p-1 w-full bg-slate-500 rounded-2xl">
          <DropDownListComponent id="ddlelement2" 
          dataSource={paramParms} 
          placeholder= 'Pressure OBS inlet'
          onChange={e => {setParameter(e.target.value)}}
          />
        </div>
      </div>
      <br />
          <div className='w-full'>
            <ChartComponent
              id="line-chart"
              height="420px"
              primaryXAxis={LinePrimaryXAxisAll}
              primaryYAxis={LinePrimaryYAxisAll}
              chartArea={{ border: { width: 0 } }}
              tooltip={{ enable: true }}
              enableMouseWheelZooming
              zoomSettings={
                {enableMouseWheelZooming: true,
                enablePan: true,
                enableScrollbar: true}
            }
              background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
              legendSettings={{ background: 'white' }}
            >
              <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
              <SeriesCollectionDirective>
                {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>
    </div>
  );
};

export default LineChartSwivel;