import React from 'react'
import Bulb from 'react-bulb';

const Bulbb = ({dacolor, dasize}) => {
  return (
    <Bulb
    size={dasize}
    color={dacolor}
    />
  )
}

export default Bulbb