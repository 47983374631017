import React from 'react';
import { withOktaAuth } from '@okta/okta-react';

export default withOktaAuth(({ authState, oktaAuth }) => {
  if (!authState) return <div>Loading...</div>;
  if (!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
    return null;
  }
  return (
    <div>
      <h1>Login Successful!</h1>
      <button onClick={() => oktaAuth.signOut()}>Logout</button>
    </div>
  );
});
