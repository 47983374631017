import React from 'react'
//"text-gray-400"
const HeaderSideBar = ({ category}) => {
    return (
      <div className='mb-5'>
        <p className="text-white">
          {category}
        </p>
      </div>
    )
  }
  
  export default HeaderSideBar