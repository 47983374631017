import React from 'react'
import {Aws_controller} from '../index';
import { LineChartGrease_Cluster} from '../index';

// AWS BANNER PULL //
// const H2Oquery ="SELECT TOP 1 h2o, location1, sample_date From dev.public.grease WHERE location1 = 'AFT' ORDER BY sample_date DESC;";
// const h2odata = await Aws_controller(H2Oquery)
// const h2ovalue = h2odata[0][0].doubleValue

// const Ironquery = "SELECT TOP 1 iron, location1, sample_date From dev.public.grease WHERE location1 = 'AFT' ORDER BY sample_date DESC;";
// const irondata = await Aws_controller(Ironquery)
// const ironvalue = irondata[0][0].doubleValue

// const Coppquery = "SELECT TOP 1 copper, location1, sample_date From dev.public.grease WHERE location1 = 'AFT' ORDER BY sample_date DESC;";
// const coppdata = await Aws_controller(Coppquery)
// const coppvalue = coppdata[0][0].doubleValue

// const PQquery = "SELECT TOP 1 pq_index, location1, sample_date From dev.public.grease WHERE location1 = 'AFT' ORDER BY sample_date DESC;";
// const pqdata = await Aws_controller(PQquery)
// const pqvalue = pqdata[0][0].doubleValue
// ################ //
const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    
  },
  chartWrapper: {
    flex: '1 1 21%',  // Ensure 4 charts in a row with some spacing
    paddingBottom: '56.25%', // 16:9 aspect ratio
    position: 'relative',
    margin: '10px',
    minWidth: '200px', // Minimum width to maintain readability
  },
  chartItem: {
    flex: '1 1 21%',  // Ensure 4 charts in a row with some spacing
   // paddingBottom: '56.25%', // 16:9 aspect ratio
    position: 'relative',
    margin: '2px',
    minWidth: '200px', // Minimum width to maintain readability
  },
};
const ChartCluster = ({id, loc}) => { 
  return (
    <div>
     <div style={styles.container}>
        
        <div style={styles.chartItem}>
               
                <LineChartGrease_Cluster theid='H2O' dtitle='Water Content [ ppm ]' loc={loc}/>

        </div>

        <div style={styles.chartItem}>
          
              {/* <h2>PQ Index</h2> */}
              <LineChartGrease_Cluster theid="Wear" dtitle= 'Wear Metals [ ppm ]' loc={loc}/>
            
          </div> 

          <div style={styles.chartItem}>
            
             {/* <h2>Wear Metals [ppm]</h2>  */}
              <LineChartGrease_Cluster theid="Additive" dtitle= 'Wear Metals [ ppm ]' loc={loc}/>
             
          </div> 

          <div style={styles.chartItem}>
          
              {/* <h2>Additive Particles [ppm]</h2>  */}
              <LineChartGrease_Cluster theid="PQ" dtitle= 'Additive Particles [ppm]' loc={loc}/>
          
        </div> 

      {/* </div> */}
{/* 
        <div className='flex flex-wrap w-3/12  '>
          <div style={{ textAlign: "center" }}>
              <h2>Iron(Fe)</h2>
              <LineChartGrease_Cluster theid="2"/>
          </div>
        </div>

        <div className='flex flex-wrap w-3/12  '>
          <div style={{ textAlign: "center" }}>
              <h2>Copper(Cu)</h2>
              <LineChartGrease_Cluster theid="3"/>
          </div>
        </div>

        <div className='flex flex-wrap w-3/12  '>
          <div style={{ textAlign: "center" }}>
              <h2>PQ Index</h2>
              <LineChartGrease_Cluster theid="4"/>
          </div>
        </div> 

      </div>
      */}
    </div>
    </div>
  )
}

export default ChartCluster