import React, {useEffect,useState} from 'react'
import * as ReactDOM from 'react-dom';

import { Banner2} from '../components';
import { Header2} from '../components';

import {  SwivelHomeData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

import {RotationTableAVG, RotationTableCUM, LineChartBreakOut, HistogramRotation} from '../components/index';

const RotationMonitoring = () => {
  const { currentColor } = useStateContext();
  return (
    <div className='mt-6'>
      <div>
        <Banner2 page="Rotation Monitoring Dashboard" statement={'Lastest Data Set Received:'}/>
      </div>

      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4
                        m-10 p-4 rounded-3xl " >
          <Header2 title="Significant Break-Out Angle 1-Day History"  thacolor= {currentColor} />
          <LineChartBreakOut daRange ='1day'/>
      </div>
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4
                        m-10 p-4 rounded-3xl " >
          <Header2 title="Significant Break-Out Angle 90-day Trending (24-hr avg)"  thacolor= {currentColor} />
          <LineChartBreakOut/>
      </div>

      <div className="flex m-0 md:m-10 mt-12 p-2 md-10 md:p-1.5 flex-wrap justify-evenly">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4
                        w-full p-6 rounded-2xl  ">
            <div className="flex justify-between">
              <Header2 title="Break-Out Angle Distribution"  thacolor= {currentColor} />
            </div>
            <div className="w-full rounded-2xl p-2 bg-white
                  border-slate-600 dark:border-white border-4">
              <HistogramRotation dacolor = {currentColor} />
            </div>
        </div>
      </div>

      <div className="flex m-0 md:m-10 mt-12 p-2 md-10 md:p-1.5  flex-wrap justify-evenly">
        <div className="w-1/2">
          <div className="p-4 rounded-3xl mr-2
                      bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
            <div className="flex justify-between">
              <Header2 title="Average Rotation Stats"  thacolor= {currentColor} />
            </div>
            <div>
              <RotationTableAVG datadata={SwivelHomeData}/>
            </div>
          </div>
        </div>

      <div className="w-1/2">
        <div className="p-4 rounded-3xl ml-2
                      bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                       border-slate-600 dark:border-white border-4">
            <div className="flex justify-between">
              <Header2 title="Cumulative Rotation Stats"  thacolor= {currentColor} />
            </div>
            <div>
              <RotationTableCUM datadata={SwivelHomeData}/>
            </div>
        </div>
      </div>

      </div>



      </div>
    
  )
}

export default RotationMonitoring