import React, {useEffect,useState} from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { Banner} from '../../components';
import { Header2 }  from '../../components';
import { S3imagesModule2, S3ListFolder, S3ListFiles, S3CSVModuleArchive, S3PDFModuleArchive} from '../../components/index'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ReactiveButton from 'reactive-button';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';



const oneone = await S3imagesModule2('FIG_1-1')

const ArchiveReports = () => {
  const { currentColor } = useStateContext();
  const [pos, setPos] = useState('2024-04');
  const list1 = S3ListFiles({mmyy:'2024-04'})
  const [alims, setAlims] = useState(null);
  const [tpms, setTpms] = useState(null);
  const [hvesr, setHvesr] = useState(null);
  const [icss, setIcss] = useState(null);
  const [lube, setLube] = useState(null);
  const [pdf, setPdf] = useState('https://mag.wcoomd.org/uploads/2018/05/blank.pdf');


  const [selectedItem, setSelectedItem] = useState({text:'2024-04'});
  const handleSelectionChange = (item) => {
    setSelectedItem(item);
  };
  console.log("asdasd1",list1)
  const buttonstyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const handleDownloadClick = ({fileUrl, fileName}) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    //console.log('a',a)
    a.click();
    document.body.removeChild(a);
    //console.log('ref',a.href)
    };


    useEffect(() => {
      if (selectedItem) {
        const fetchDataWithSelectedItem = async () => {
          // Replace this with the actual async operation
          try {
            console.log('Processing selectedItem: ', selectedItem);
            // Example async function using selectedItem
            const fetchedAlims = await S3CSVModuleArchive({month: selectedItem.text ,csvname:'ALIMS_DS'})
            setAlims(fetchedAlims); // Store the result in state
            const fetchedHvesr = await S3CSVModuleArchive({month: selectedItem.text ,csvname:'HVESR_DS'})
            setHvesr(fetchedHvesr);
            const fetchedIcss = await S3CSVModuleArchive({month: selectedItem.text ,csvname:'ICSS_DS'})
            setIcss(fetchedIcss); // Store the result in state
            const fetchedLube = await S3CSVModuleArchive({month: selectedItem.text ,csvname:'LUBE_DS'})
            setLube(fetchedLube); 
            const fetchedTpms = await S3CSVModuleArchive({month: selectedItem.text ,csvname:'TPMS_DS'})
            setTpms(fetchedTpms);  // Store the result in state
            const fetchedPdf = await S3PDFModuleArchive( selectedItem.text )
            setPdf(fetchedPdf);  // Store the result in state
            console.log('Result: ', fetchedTpms);
          } catch (error) {
            console.error('Error fetching data: ', error);
          }
        };

        fetchDataWithSelectedItem();
      }
    }, [selectedItem]);

    // Example of an async function
    const fetchSomeData = async (item) => {
      // Simulate an async operation, such as fetching data from an API
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(`Data for item ${item.text}`);
        }, 2000);
      });
    };

  //For loop here using S3imagesModule and list 1
  //console.log(<S3ListFolder/>)
  return (
    <div className='mt-12'>
      <Banner page="Archive Reports"/>
      <div>
            <S3ListFolder onSelectionChange={handleSelectionChange} />

      </div>
      <div className='m-2 md:m-10 mt-2 p-2 md-10  rounded-3xl
                  bg-white dark:text-gray-200 dark:bg-slate-200
                  border-slate-600 dark:border-white border-4'>
        <div className= 'flex flex-wrap w-full'>
              
          <div className= 'flex flex-wrap w-1/2 justify-start' >
            <div className= 'mt-1 flex flex-wrap justify-center' >
                <p className="font-semibold text-3xl" style={{color:currentColor}}>{selectedItem.text} Data Sets</p>                     
            </div>
              <div style={buttonstyle}>
                <ReactiveButton 
                    shadow
                    rounded
                    color='secondary'
                    buttonState={'idle'}
                    idleText={'ALIMS'}
                    onClick={() => handleDownloadClick({fileUrl:alims,fileName:'ALIMS_DS.csv'})}
                />
                <ReactiveButton 
                    shadow
                    rounded
                    color='secondary'
                    buttonState={'idle'}
                    idleText={'HVESR'}
                    onClick={() => handleDownloadClick({fileUrl:hvesr,fileName:'HVESR_DS.csv'})}
                />
                <ReactiveButton 
                    shadow
                    rounded
                    color='secondary'
                    buttonState={'idle'}
                    idleText={'ICSS'}
                    onClick={() => handleDownloadClick({fileUrl:icss,fileName:'ICSS_DS.csv'})}
                />
                <ReactiveButton 
                    shadow
                    rounded
                    color='secondary'
                    buttonState={'idle'}
                    idleText={'LUBE'}
                    onClick={() => handleDownloadClick({fileUrl:lube,fileName:'LUBE_DS.csv'})}
                />
                <ReactiveButton 
                    shadow
                    rounded
                    color='secondary'
                    buttonState={'idle'}
                    idleText={'TPMS'}
                    onClick={() => handleDownloadClick({fileUrl:tpms,fileName:'TPMS_DS.csv'})}
                />
            </div>
          </div>

          
          <div className= 'flex flex-wrap w-1/2 justify-end' >
              <div className= 'mt-1 flex flex-wrap justify-center' >
                  <p className="font-semibold text-3xl" style={{color:currentColor}}>{selectedItem.text} Pdf Download</p>                     
              </div>
              <div className= 'mt-1 flex flex-wrap justify-center' >
                <div style={buttonstyle}>
                  <ReactiveButton 
                      shadow
                      rounded
                      color='secondary'
                      buttonState={'idle'}
                      idleText={'PDF Report'}
                      onClick={() => handleDownloadClick({fileUrl: pdf,fileName:'4008-RPT-0003.pdf'})}
                  />
                </div>
              </div>

          </div>
        </div>   
      </div>

      <div className='m-2 md:m-10 mt-2 p-2 md-10  rounded-3xl
                  bg-white dark:text-gray-200 dark:bg-slate-200
                  border-slate-600 dark:border-white border-4'>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl = {pdf} />
        </Worker>

      </div>

      <div className='dark:text-gray-200'>
            <h2>Selected Item:</h2>
            <p>{selectedItem ? selectedItem.text : '2024-04'}</p>

      </div>


    </div>
  )
}

export default ArchiveReports